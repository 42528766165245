<template>
  <div class="users">
    <v-subheader class="py-0 d-flex justify-space-between rounded-lg">
      <TitleComponent title="Utilizatori" class="hide-on-mobile" />
      <v-spacer></v-spacer>
      <ErrorAlert :message="errorMessage" @clear-error="clearMessage" />
      <SuccessAlert :message="successMessage" @clear-error="clearMessage" />
      <UserForm ref="userForm" @updateUserData="fetchUserData" />
    </v-subheader>
    <br />
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="d-flex align-center justify-end pe-2">
            <div class="hide-on-mobile">&nbsp; Tabelul Utilizatorilor</div>
            <v-spacer></v-spacer>
            <SearchBar @search="handleSearch" />
            <v-spacer></v-spacer>
            <ColumnSelect
              :headers="headers"
              :updateVisibleColumns="updateVisibleColumns"
            />
          </v-card-title>

          <v-divider></v-divider>
          <v-data-table
            :mobile-breakpoint="0"
            :headers="visibleHeaders"
            :items="filteredData"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :loading="isLoading"
            loading-text="Incărcare... Aștepta-ți vă rog"
            class="elevation-1"
          >
            <template v-slot:item="{ item, index }">
              <tr :class="{ 'light-green-row': index % 2 !== 0 }">
                <td
                  v-for="(header, hIndex) in visibleHeaders"
                  :key="hIndex"
                  :style="header.style ? header.style : {}"
                  :class="{ 'red-row': item.isActive === 'Blocat' }"
                >
                  {{ getTableCellValue(item, header, index) }}
                  <div v-if="header.value === 'actions'" class="nowrap-icons">
                    <v-icon size="big" class="me-2" @click="viewItem(item)">
                      mdi-eye
                    </v-icon>
                    <v-icon size="big" class="me-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon size="big" @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <EditUserPopup
      ref="editUserPopup"
      :user="selectedUser"
      :visible="showEditPopup"
      @updateUserData="fetchUserData"
    />
    <DeleteItem
      ref="deleteItem"
      :user="selectedUser"
      :visible="dialogDelete"
      @updateUserData="fetchUserData"
    />
    <ViewItem
      ref="viewItem"
      :user="selectedUser"
      :visible="dialogDelete"
      @updateUserData="fetchUserData"
    />
  </div>
</template>

<script>
import * as api from "./api";
import * as helper from "@/helper/helper.js";
import TitleComponent from "@/components/elements/TitleComponent.vue";
import ColumnSelect from "@/components/elements/ColumnSelect.vue";
import SearchBar from "@/components/elements/SearchBar.vue";
import UserForm from "./UserForm.vue";
import EditUserPopup from "./EditUserPopup.vue";
import DeleteItem from "./DeleteItem.vue";
import ViewItem from "./ViewItem.vue";
import ErrorAlert from "@/components/alerts/ErrorAlert.vue";
import SuccessAlert from "@/components/alerts/SuccessAlert.vue";

export default {
  name: "UsersPage",
  components: {
    UserForm,
    EditUserPopup,
    DeleteItem,
    TitleComponent,
    ErrorAlert,
    ColumnSelect,
    SearchBar,
    SuccessAlert,
    ViewItem,
  },
  data() {
    return {
      headers: [],
      userData: [],
      visibleHeaders: [],
      selectedUser: null,
      showEditPopup: false,
      dialogDelete: false,
      search: "",
      errorMessage: "",
      successMessage: "",
      sortBy: "id",
      sortDesc: false,
      isLoading: true,
    };
  },
  methods: {
    async fetchUserData() {
      try {
        this.userData = await api.getUsersTableData();
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    viewItem(item) {
      this.selectedUser = { ...item };
      this.$refs.viewItem.show();
    },
    editItem(item) {
    // console.log("Selected user to edit:", item); // Add this log to check the data
    this.selectedUser = { ...item };
    this.$refs.editUserPopup.show();
  },
    deleteItem(item) {
      this.selectedUser = { ...item };
      this.$refs.deleteItem.show();
    },
    handleError(message) {
      this.errorMessage = message;
    },
    handleSuccess(message) {
      this.successMessage = message;
    },
    clearMessage() {
      this.errorMessage = "";
      this.successMessage = "";
    },
    handleSearch(searchTerm) {
      this.search = searchTerm;
    },
    getTableCellValue(item, header, index) {
      if (header.value === "index") return index + 1;
      if (header.value === "full_name") return item.full_name;
      if (header.value === "user_name") return item.user_name;
      if (header.value === "role") return item.roleId.role;
      if (header.value === "isActive") return item.isActive;
      if (header.value === "idnp") return item.idnp !== "" ? item.idnp : "";
      if (header.value === "email") return item.email;
      if (header.value === "department") return item.department;
      if (header.value === "telephone") return item.telephone || "Nu există"; 
      if (header.value === "landline") return item.landline || "Nu există";
      return "";
    },
    sortTable(header) {
      const result = helper.sortTable(header);
      return result;
    },
    updateVisibleColumns() {
      this.visibleHeaders = this.headers.filter((header) => header.visible);
    },
  },

  computed: {
    filteredData() {
      const result = helper.filteredData(this.userData, this.search);
      return result;
    },
  },
  mounted() {
    this.headers = require("./users.json");
    this.visibleHeaders = this.headers.filter(
      (header) => header.defaultVisible
    );
    this.headers.forEach((header) => {
      header.visible = header.defaultVisible;
    });
    this.fetchUserData();
    this.$refs.deleteItem.$on("error", this.handleError);
    this.$refs.deleteItem.$on("success", this.handleSuccess);
    this.$refs.editUserPopup.$on("error", this.handleError);
    this.$refs.editUserPopup.$on("success", this.handleSuccess);
    this.$refs.userForm.$on("error", this.handleError);
    this.$refs.userForm.$on("success", this.handleSuccess);
    this.$refs.viewItem.$on("error", this.handleError);
  },
};
</script>

<style scoped>
.users .v-data-table::v-deep tbody td {
  font-size: 18px !important;
}
.v-data-table::v-deep th {
  font-size: 18px !important;
  background-color: #2d8659 !important;
  color: white !important;
  white-space: nowrap;
}
.light-green-row {
  background-color: #ecf9f2;
}
.nowrap-icons {
  white-space: nowrap;
}
.red-row {
  background-color: #f29daa;
}
@media (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}
</style>
