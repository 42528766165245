<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <DeleteCard
      title="Doriți să ștergeți acest utilizator?"
      @cancel="cancelDelete"
      @confirm="confirmDelete"
    />
  </v-dialog>
</template>

<script>
import { deleteUser } from "./api.js";
import DeleteCard from "@/components/elements/DeleteCard.vue";

export default {
  props: {
    user: Object,
  },
  components: {
    DeleteCard,
  },
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    show() {
      this.dialogDelete = true;
    },
    cancelDelete() {
      this.dialogDelete = false;
    },
    async confirmDelete() {
      try {
        if (
          this.user.idnp === "2009030001638" &&
          this.user.roleId.role === "admin"
        ) {
          this.dialogDelete = false;
          this.$emit("error", "Nu puteți șterge adminul principal.");
          return;
        }
        await deleteUser(this.user.id);
        this.$emit("updateUserData");
        this.dialogDelete = false;
        this.$emit("success", "Ștergere cu success");
      } catch (error) {
        console.error("Error deleting user:", error);
        this.$emit("error", error);
        this.dialogDelete = false;
      }
    },
  },
};
</script>
