<template>
  <v-dialog v-model="showEditPopup" persistent width="1024">
    <v-card>
      <v-card-title
        >Editează echipament
        <v-spacer></v-spacer>
        <v-btn icon dark color="black" @click="showEditPopup = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="updateEquipment">
          <v-container>
            <v-row>
              <v-col v-if="isAdmin" cols="12">
                <v-select
                  v-model="selectedUser"
                  :items="users"
                  label="Persoana Responsabila*"
                  item-text="user"
                  item-value="id"
                  required
                  :error-messages="formErrors.selectedUser"
                ></v-select>
              </v-col>
              <v-col cols="6" sm="6">
                <v-select
                  v-model="selectedType"
                  :items="types"
                  label="Tip echipament*"
                  item-text="type"
                  item-value="id"
                  required
                  :error-messages="formErrors.selectedType"
                ></v-select>
              </v-col>
              <v-col cols="6" md="4">
                <v-checkbox
                  v-model="isFunctional"
                  label="Este activ"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="model"
                  label="Model*"
                  required
                  :error-messages="formErrors.model"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="registration"
                  label="Nr. Inventar*"
                  required
                  :error-messages="formErrors.registration"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="selectedBlock"
                  :items="blocks"
                  label="Select Block*"
                  item-text="block"
                  item-value="block"
                  required
                  @change="fetchFloors"
                  :error-messages="formErrors.selectedBlock"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="selectedFloor"
                  :items="floors"
                  label="Select Floor*"
                  item-text="floor"
                  item-value="floor"
                  required
                  @change="fetchRooms"
                  :error-messages="formErrors.selectedFloor"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="selectedRoom"
                  :items="rooms"
                  label="Select Room*"
                  item-text="room"
                  item-value="room"
                  required
                  :error-messages="formErrors.selectedRoom"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-btn
                  style="color: white; background-color: #2d8654"
                  @click="resetDepartment"
                  >Resetează Dep.</v-btn
                >
                <v-treeview
                  :items="departmentTree"
                  item-key="id"
                  item-text="name"
                  selectable
                  dense
                  open-on-click
                  :value="selectedDepartment ? [selectedDepartment.id] : []"
                  @input="updateSelectedDepartment"
                  label="Select Department*"
                  :disabled="isTreeviewDisabled"
                >
                </v-treeview>
                <span class="error-message red--text">
                  {{ formErrors.selectedDepartment }}
                </span>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              style="color: white; background-color: #2d8654"
              variant="text"
              >Actualizează</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import * as api from "./api";
import { mapGetters } from "vuex";

export default {
  props: {
    equipment: Object,
  },
  data() {
    return {
      users: [],
      types: [],
      blocks: [],
      floors: [],
      rooms: [],
      departments: [],
      isFunctional: true,
      showEditPopup: false,
      selectedUser: null,
      selectedType: null,
      selectedBlock: null,
      selectedFloor: null,
      selectedRoom: null,
      selectedDepartment: null,
      model: "",
      registration: "",
      isTreeviewDisabled: true,
      editedEquipment: { ...this.equipment },
      formErrors: {
        selectedUser: "",
        selectedType: "",
        model: "",
        registration: "",
        registration: "",
        selectedBlock: "",
        selectedFloor: "",
        selectedRoom: "",
        selectedDepartment: "",
      },
    };
  },
  methods: {
    show() {
      this.showEditPopup = true;
      this.isTreeviewDisabled = true;
    },
    async fetchData() {
      try {
        this.types = await api.fetchEquipmentTypes();
        this.users = await api.getUsersDropDown();
        this.departments = await api.getDepartments();
        this.blocks = await api.fetchBlocks();
      } catch (error) {
        console.error("Error fetching data:", error);
        this.$emit("error", error);
      }
    },
    async fetchFloors() {
      if (this.selectedBlock) {
        try {
          this.floors = await api.getFloorsByBlock(this.selectedBlock);
        } catch (error) {
          console.error("Error fetching floors:", error);
          this.$emit("error", error);
        }
      } else {
        this.floors = [];
      }
    },
    async fetchRooms() {
      if (this.selectedBlock && this.selectedFloor) {
        try {
          this.rooms = await api.getRoomsByBlockAndFloor(
            this.selectedBlock,
            this.selectedFloor
          );
        } catch (error) {
          console.error("Error fetching rooms:", error);
          this.$emit("error", error);
        }
      } else {
        this.rooms = [];
      }
    },
    async updateEquipment() {
      this.validateForm();

      if (!this.isFormValid()) {
        return;
      }

      try {
        this.editedEquipment.id = this.equipment.id;
        const locationId = await api.getLocationIdByDetails(
          this.selectedBlock,
          this.selectedFloor,
          this.selectedRoom
        );

        const equipmentDataToUpdate = {
          id: this.editedEquipment.id,
          equipment_type_id: this.selectedType,
          model: this.model,
          registration_number: this.registration,
          responsible_person_id: this.isAdmin
            ? this.selectedUser
            : this.getUser.userId,
          location_id: locationId,
          department_id: this.selectedDepartment
            ? this.selectedDepartment.id
            : null,
          isFunctional: this.isFunctional,
        };

        await api.updateEquipment(equipmentDataToUpdate);
        this.showEditPopup = false;

        this.$emit("updateEquipmentData");
        this.$emit("success", "Editare cu success");
      } catch (error) {
        console.error("Error updating equipment:", error);
        this.$emit("error", error);
      }
    },
    convertToTree(items, parentId = 0) {
      const result = [];
      items.forEach((item) => {
        if (item.parent_id === parentId) {
          const children = this.convertToTree(items, item.id);
          const newItem = {
            id: item.id,
            name: item.name,
            children: children,
          };
          result.push(newItem);
        }
      });
      return result;
    },
    updateSelectedDepartment(newValues) {
      if (newValues.length === 1) {
        const selectedDepartmentId = newValues[0];
        this.selectedDepartment = this.departments.find(
          (department) => department.id === selectedDepartmentId
        );
        this.isTreeviewDisabled = true;
      } else {
        this.selectedDepartment = null;
        this.isTreeviewDisabled = false;
      }
    },
    resetForm() {
      this.formErrors = {
        selectedUser: "",
        selectedType: "",
        model: "",
        registration: "",
        selectedBlock: "",
        selectedFloor: "",
        selectedRoom: "",
        selectedDepartment: "",
      };
    },
    resetDepartment() {
      this.selectedDepartment = [];
      this.isTreeviewDisabled = false;
      this.formErrors.selectedDepartment = "";
    },
    isFormValid() {
      return !Object.values(this.formErrors).some((error) => error);
    },
    validateForm() {
      this.formErrors.selectedUser =
        this.isAdmin && !this.selectedUser
          ? "Selectați o persoană responsabilă."
          : "";
      this.formErrors.selectedType = !this.selectedType
        ? "Selectați un tip de echipament."
        : "";
      this.formErrors.model = !this.model ? "Introduceți un model." : "";
      this.formErrors.registration = !this.registration
        ? "Introduceți numarul de inventar (codul)."
        : "";
      this.formErrors.registration = !this.registration
        ? "Introduceți un număr de înregistrare."
        : "";
      this.formErrors.selectedBlock = !this.selectedBlock
        ? "Selectați un bloc."
        : "";
      this.formErrors.selectedFloor = !this.selectedFloor
        ? "Selectați un etaj."
        : "";
      this.formErrors.selectedRoom = !this.selectedRoom
        ? "Selectați o cameră."
        : "";
      this.formErrors.selectedDepartment =
        !this.selectedDepartment ||
        this.selectedDepartment.length === 0 ||
        this.selectedDepartment.length > 1
          ? "Selectați doar un departament."
          : "";
    },
  },
  watch: {
    showEditPopup(newVal) {
      if (!newVal) {
        this.resetForm();
      }
    },
    equipment: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.selectedUser = this.users.find(
            (user) => String(user.user) === String(newValue.responsable_person)
          );
          this.selectedType = this.types.find(
            (type) => String(type.type) === String(newValue.equipmentType)
          );
          if (newValue.departmentId != null) {
            this.selectedDepartment = this.departments.find(
              (department) =>
                String(department.id) === String(newValue.departmentId.id)
            );
          } else {
            this.selectedDepartment = null;
          }

          this.selectedBlock = newValue.block;
          this.selectedFloor = newValue.floor;
          this.selectedRoom = newValue.room;
          this.model = newValue.model;
          this.registration = newValue.registration_number;
          this.isFunctional = newValue.isFunctional;
        }
      },
    },
    selectedUser(newValue) {
      if (newValue) {
        this.editedEquipment.responsible_person_id = newValue;
        this.formErrors.selectedUser = "";
      } else {
        this.editedEquipment.responsible_person_id = null;
      }
    },
    selectedType(newValue) {
      if (newValue) {
        this.editedEquipment.equipment_type_id = newValue;
        this.formErrors.selectedType = "";
      } else {
        this.editedEquipment.equipment_type_id = null;
      }
    },
    selectedBlock(newValue) {
      if (newValue) {
        this.fetchFloors();
        this.formErrors.selectedBlock = "";
      } else {
        this.floors = [];
      }
    },
    selectedFloor(newValue) {
      if (newValue) {
        this.fetchRooms();
        this.formErrors.selectedFloor = "";
      } else {
        this.rooms = [];
      }
    },
    selectedRoom(newVal) {
      this.formErrors.selectedRoom = "";
    },
    selectedDepartment(newVal) {
      this.formErrors.selectedDepartment = "";
    },
  },
  computed: {
    ...mapGetters(["getUser", "isAdmin"]),
    departmentTree() {
      if (this.departments && this.departments.length > 0) {
        return this.convertToTree(this.departments);
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.editedEquipment = { ...this.equipment };
    this.fetchData();
  },
};
</script>
