import { render, staticRenderFns } from "./ViewItem.vue?vue&type=template&id=d33f9480&scoped=true"
import script from "./ViewItem.vue?vue&type=script&lang=js"
export * from "./ViewItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d33f9480",
  null
  
)

export default component.exports