<template>
  <div>
    <v-alert dense text type="success">
      <strong>Autentificare cu succes!</strong>
    </v-alert>
  </div>
</template>

<script>
import * as helper from "@/helper/helper";

export default {
  mounted() {
    helper.requestNotificationPermission();
    helper.setupWebSocket(this, this.$store.getters.getUser.role);
  },
};
</script>
