export function calcTimeDifference(created_time) {
    const [day, month, year] = created_time.split("-").map(Number);
    const createdDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    const timeDifference = currentDate - createdDate;
    const millisecondsInDay = 1000 * 60 * 60 * 24;
    const daysDifference = Math.floor(timeDifference / millisecondsInDay);

    let result = "";

    const yearsDifference = Math.floor(daysDifference / 365);
    const monthsDifference = Math.floor((daysDifference % 365) / 30);
    const remainingDays = daysDifference % 30;

    if (yearsDifference > 0) {
        result += `${yearsDifference} ${yearsDifference === 1 ? "an" : "ani"}`;
        if (monthsDifference > 0 || remainingDays > 0) {
            result += ", ";
        }
    }

    if (monthsDifference > 0) {
        result += `${monthsDifference} ${monthsDifference === 1 ? "lună" : "luni"}`;
        if (remainingDays > 0) {
            result += " și ";
        }
    }

    if (
        remainingDays > 0 ||
        (yearsDifference === 0 && monthsDifference === 0)
    ) {
        result += `${remainingDays} ${remainingDays === 1 ? "zi" : "zile"}`;
    }

    result += " în urmă";

    return result;
}

export function formatCreatedAt(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("ro-MD", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
    return formattedDate;
}

export function sortTable(header) {
    if (header.sortable) {
        if (this.sortBy === header.value) {
            this.sortDesc = !this.sortDesc;
        } else {
            this.sortBy = header.value;
            this.sortDesc = false;
        }
    }
}

export function filteredData(approvalData, search) {
    const searchTerm = search.toLowerCase().trim();
    return approvalData.filter((item) => {
        return Object.entries(item).some(([key, value]) => {
            if (value !== null && typeof value !== "object") {
                return value.toString().toLowerCase().includes(searchTerm);
            } else if (value !== null && typeof value === "object") {
                return Object.values(value).some((nestedValue) => {
                    return nestedValue.toString().toLowerCase().includes(searchTerm);
                });
            }
            return false;
        });
    });
}

export function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const domain = "usarb.md";
    // const isCorporateEmail = email.endsWith("@" + domain);
    return emailRegex.test(email);
}

export function validateIDNP(idnp) {
    const idnpLength = idnp.length;
    const idnpRegex = /^\d+$/;
    return idnpLength === 13 && idnpRegex.test(idnp);
}

export function validateTelephone(telephone) {
    const telephoneLength = telephone.length;
    const telephoneRegex = /^\d*$/;
    return telephoneLength === 9 && telephoneRegex.test(telephone);
}
export function validateLandline(landline) {
    const landlineRegex = /^\d+$/;

    // Ensure the input is numeric
    if (!landlineRegex.test(landline)) {
        return false;
    }

    // If the landline is exactly 3 digits, format it to '023152XXX'
    if (landline.length === 3) {
        return `023152${landline}`;
    }

    // If the landline is not exactly 9 digits after formatting, it's invalid
    if (landline.length !== 9) {
        return false;
    }

    return landline;
}



export async function requestNotificationPermission() {
    if (Notification.permission !== "granted") {
        const permission = await Notification.requestPermission();
        if (permission !== "granted") {
            console.log("Notification permission denied");
            return permission;
        }
    }
    return Notification.permission;
}

export function displayNotification(notification) {
    try {
        const { title, body, requestType, responseType, userFirstName, userLastName } = notification;
        console.log("Displaying notification:", title, body);

        if (Notification.permission === "granted") {
            const notificationObj = new Notification(title, {
                body: body,
            });

            notificationObj.onclick = () => {
                console.log("Notification clicked");
                // window.focus();
                window.open("https://suport.usarb.md/approval", '_blank');
                notificationObj.close();
            };
        } else {
            console.warn("Notification permission not granted");
        }
    } catch (error) {
        console.error("Error displaying notification:", error);
    }
}

export function setupWebSocket(vueInstance, userRole) {
    const socket = vueInstance.$socket;
    if (!socket) {
        console.error("WebSocket instance not found.");
        return;
    }

    socket.on('connect', () => {
        console.log("WebSocket connected");
    });

    socket.on('disconnect', () => {
        console.log("WebSocket disconnected");
    });

    socket.on('notificationMessage', message => {
        // console.log("Received message:", message);
        displayNotification(message);
    });

    socket.on('error', error => {
        console.error("WebSocket error:", error);
    });
}

// export function setupWebSocket(vueInstance, userRole) {
//     const socket = vueInstance.$socket;
//     if (!socket) {
//         console.error("WebSocket instance not found.");
//         return;
//     }

//     socket.on('connect', () => {
//         console.log("WebSocket connected");
//     });

//     socket.on('disconnect', () => {
//         console.log("WebSocket disconnected");
//     });

//     socket.on('notificationMessage', message => {
//         const roleMap = {
//             1: 'usersef',
//             2: 'rector',
//             5: 'dtisef',
//             3: 'inginer',
//             6: 'inginer',
//         };

//         const allowedRole = roleMap[message.responseType];

//         if (allowedRole && userRole === allowedRole) {
//             console.log("Received message:", message);
//             displayNotification(message);
//         } else {
//             console.log("Notification not for this user role:", userRole);
//         }
//     });

//     socket.on('error', error => {
//         console.error("WebSocket error:", error);
//     });
// }
