import axios from '@/store/interceptor';

import apiUrl from '../../config/api.config'

export async function getDepartments() {
    try {
        const response = await axios.get(`${apiUrl}/departments`);
        return response.data;
    } catch (error) {
        console.error("Error fetching departments:", error);
        throw error;
    }
}

export async function fetchBlocks() {
    try {
        const response = await axios.get(`${apiUrl}/locations`);
        return response.data;
    } catch (error) {
        console.error("Error fetching blocks:", error);
        throw error;
    }
}

export async function getFloorsByBlock(block) {
    try {
        const response = await axios.get(`${apiUrl}/locations/${block}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching floors:", error);
        throw error;
    }
}

export async function getRoomsByBlockAndFloor(block, floor) {
    try {
        const response = await axios.get(`${apiUrl}/locations/${block}/${floor}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching rooms:", error);
        throw error;
    }
}

export async function getLocationIdByDetails(selectedBlock, selectedFloor, selectedRoom) {
    try {
        const response = await axios.get(
            `${apiUrl}/locations/id?block=${selectedBlock}&floor=${selectedFloor}&room=${selectedRoom}`
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching location id:", error);
        throw error;
    }
}

export async function getEquipments() {
    try {
        const response = await axios.get(`${apiUrl}/equipments`);
        return response.data;
    } catch (error) {
        console.error("Error fetching equipments:", error);
        throw error;
    }
}
export async function getEquipmentsTableData() {
    try {
        const response = await axios.get(`${apiUrl}/equipments/equipments-table-data`);
        return response.data;
    } catch (error) {
        console.error("Error fetching equipments:", error);
        throw error;
    }
}

export async function getEquipmentsTableDataByUser(personId) {
    try {
        const response = await axios.get(`${apiUrl}/equipments/${personId}/user-equipments-table-data`);
        return response.data;
    } catch (error) {
        console.error("Error fetching equipments:", error);
        throw error;
    }
}

export async function getResponsiblePersonData(equipmentId) {
    try {
        const response = await axios.get(`${apiUrl}/equipments/${equipmentId}/responsible-person`);
        return response.data;
    } catch (error) {
        console.error("Failed to fetch responsible person data:", error);
        throw error;
    }
}

export async function checkEquipment(equipmentId) {
    try {
        const response = await axios.get(`${apiUrl}/requests/${equipmentId}/check-equipment`);
        return response.data;
    } catch (error) {
        console.error("Error fetching equipments:", error);
        throw error;
    }
}

export async function fetchEquipmentTypes() {
    try {
        const response = await axios.get(`${apiUrl}/equipment-types`);
        return response.data;
    } catch (error) {
        console.error("Error fetching equipment-types:", error);
        throw error;
    }
}

export async function getUsersDropDown() {
    try {
        const response = await axios.get(`${apiUrl}/users/user-dropdown`);
        return response.data;
    } catch (error) {
        console.error("Error fetching user-dropdown:", error);
        throw error;
    }
}

export async function saveEquipmentData(postData) {
    try {
        const response = await axios.post(`${apiUrl}/equipments`, postData, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error saving equipment:", error);
        throw error;
    }
}

export async function updateEquipment(equipmentData) {
    try {
        const response = await axios.patch(`${apiUrl}/equipments/${equipmentData.id}`, equipmentData, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error updating equipment:", error);
        throw error;
    }
}


export async function deleteEquipment(id) {
    try {
        const response = await axios.delete(`${apiUrl}/equipments/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting equipments:", error);
        throw error;
    }
}
