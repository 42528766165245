<template>
  <div class="requests">
    <v-subheader class="py-0 d-flex justify-space-between rounded-lg">
      <TitleComponent title="Demersuri" class="hide-on-mobile" />
      <v-spacer></v-spacer>
      <ErrorAlert :message="errorMessage" @clear-error="clearMessage" />
      <SuccessAlert :message="successMessage" @clear-error="clearMessage" />
      <DialogForm ref="dialogForm" @updateRequestData="fetchRequestData" />
    </v-subheader>
    <br />
    <v-row justify="end" v-if="isEngineer || isAdmin || isContability">
      <v-dialog v-model="showDateRangePicker" persistent width="400">
        <template v-slot:activator="{ props }">
          <v-btn
            x-large
            v-bind="props"
            @click="showDateRangePicker = true"
            style="color: white; background-color: #2d8654"
          >
            Descarcă Raportul
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Selectează Intervalul de Date</span>
            <v-spacer></v-spacer>
            <v-btn icon dark color="black" @click="closeDateRangePicker">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-menu
                    ref="startMenu"
                    v-model="startMenu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedStartDate"
                        label="Data Începerii"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      scrollable
                      @change="saveStartDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    ref="endMenu"
                    v-model="endMenu"
                    :close-on-content-click="false"
                    :return-value.sync="endDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedEndDate"
                        label="Data Încheierii"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      no-title
                      scrollable
                      @change="saveEndDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="color: white; background-color: #2d8654"
              variant="text"
              @click="downloadReport"
            >
              Descarcă
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <br />
    <!-- Existing Table Section -->
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="d-flex align-center pe-2">
            <div>&nbsp; Tabelul Demersurilor</div>
            <v-spacer></v-spacer>
            <SearchBar @search="handleSearch" />
            <v-spacer></v-spacer>
            <ColumnSelect
              :headers="headers"
              :updateVisibleColumns="updateVisibleColumns"
            />
          </v-card-title>
          <v-data-table
            :mobile-breakpoint="0"
            :headers="visibleHeaders"
            :items="filteredData"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :loading="isLoading"
            loading-text="Incărcare... Aștepta-ți vă rog"
            class="elevation-1"
          >
            <template v-slot:item="{ item, index }">
              <tr :class="{ 'light-green-row': index % 2 !== 0 }">
                <td
                  v-for="(header, hIndex) in visibleHeaders"
                  :key="hIndex"
                  :style="header.style ? header.style : {}"
                  :class="{ 'red-row': item.status === 'Respins' }"
                >
                  {{ getTableCellValue(item, header, index) }}
                  <div v-if="header.value === 'actions'">
                    <v-icon size="big" @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <DeleteItem
      ref="deleteItem"
      :request="selectedRequest"
      :visible="dialogDelete"
      @updateRequestData="fetchRequestData"
    />
  </div>
</template>

<script>
import DialogForm from "./DialogForm.vue";
import DeleteItem from "./DeleteItem.vue";
import TitleComponent from "@/components/elements/TitleComponent.vue";
import ColumnSelect from "@/components/elements/ColumnSelect.vue";
import SearchBar from "@/components/elements/SearchBar.vue";
import SuccessAlert from "@/components/alerts/SuccessAlert.vue";
import ErrorAlert from "@/components/alerts/ErrorAlert.vue";
import * as api from "./api";
import * as helper from "@/helper/helper.js";
import { mapGetters } from "vuex";

export default {
  name: "RequestsPage",
  components: {
    DialogForm,
    DeleteItem,
    TitleComponent,
    ColumnSelect,
    SearchBar,
    SuccessAlert,
    ErrorAlert,
  },
  data() {
    return {
      headers: [],
      requestsData: [],
      visibleHeaders: [],
      dialogDelete: false,
      selectedRequest: null,
      search: "",
      sortBy: "id",
      errorMessage: "",
      successMessage: "",
      sortDesc: true,
      acction: false,
      isLoading: true,
      startDate: '',
      endDate: '',
      formattedStartDate: '',
      formattedEndDate: '',
      startMenu: false,
      endMenu: false,
      showDateRangePicker: false,
    };
  },
  watch: {
    startDate(newValue) {
      this.formattedStartDate = newValue ? newValue : '';
    },
    endDate(newValue) {
      this.formattedEndDate = newValue ? newValue : '';
    },
  },
  methods: {
    async fetchRequestData() {
      // await new Promise((f) => setTimeout(f, 1000));
      try {
        if (this.isAdmin || this.isDtiSef || this.isEngineer) {
          const requestsResponse = await api.getAllRequestsTableData();
          this.requestsData = requestsResponse;
        } else {
          const requestsResponse = await api.getRequestsTableDataByUser(
            this.getUser.userId
          );
          this.requestsData = requestsResponse;
        }
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    deleteItem(item) {
    this.selectedRequest = { ...item };
    this.$refs.deleteItem.show();
  },
  saveStartDate(date) {
    this.startDate = date;
    this.formattedStartDate = date;
    this.$refs.startMenu.save(date);  // Explicitly close the start menu
  },
  saveEndDate(date) {
    this.endDate = date;
    this.formattedEndDate = date;
    this.$refs.endMenu.save(date);  // Explicitly close the end menu
  },
  closeDateRangePicker() {
    this.showDateRangePicker = false;
  },
  async downloadReport() {
    try {
      if (!this.startDate || !this.endDate) {
        this.handleError("Selectează un interval de date valid.");
        return;
      }

      const params = {
        start: this.startDate,
        end: this.endDate
      };

      const response = await api.downloadReport(params);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Report_${this.startDate}_${this.endDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.showDateRangePicker = false;
    } catch (error) {
      console.error("Error downloading report:", error);
      this.handleError("Failed to download the report.");
    }
  },
    formatCreatedAt(dateString) {
      const result = helper.formatCreatedAt(dateString);
      return result;
    },
    getTableCellValue(item, header, index) {
      if (header.value === "index") return index + 1;
      if (header.value === "user") return item.user;
      if (header.value === "status") return item.status;
      if (header.value === "equipment") return item.equipment;
      if (header.value === "model") return item.model;
      if (header.value === "registration_number")
        return item.registration_number;
      if (header.value === "cause") return item.cause;
      if (
        header.value === "service_price" &&
        (this.isAdmin || this.isEngineer || this.isDtiSef)
      ) {
        return item.service_price;
      }
      if (header.value === "date") return this.formatCreatedAt(item.date);
      if (header.value === "actions") return "";
      return "";
    },
    sortTable(header) {
      const result = helper.sortTable(header);
      return result;
    },
    updateVisibleColumns() {
      if (this.isAdmin || this.isEngineer || this.isDtiSef) {
        this.visibleHeaders = this.headers.filter((header) => header.visible);
      } else {
        this.visibleHeaders = this.headers.filter(
          (header) => header.visible && header.notForUser
        );
      }
    },
    handleError(message) {
      this.errorMessage = message;
    },
    handleSuccess(message) {
      this.successMessage = message;
    },
    handleSearch(searchTerm) {
      this.search = searchTerm;
    },
    clearMessage() {
      this.errorMessage = "";
      this.successMessage = "";
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    isAdmin() {
      return this.$store.getters.getUser.role === "admin";
    },
    isEngineer() {
      return this.$store.getters.getUser.role === "inginer";
    },
    isDtiSef() {
      return this.$store.getters.getUser.role === "dtisef";
    },
    isContability() {
      return this.$store.getters.getUser.role === "contability";
    },
    filteredData() {
      const result = helper.filteredData(this.requestsData, this.search);
      return result;
    },
  },
  mounted() {
    this.headers = require("./requests.json");
    if (!(this.isAdmin || this.isEngineer || this.isDtiSef)) {
      this.visibleHeaders = this.headers.filter(
        (header) => header.defaultVisible && header.notForUser
      );
      this.headers = this.headers.filter(
        (header) => header.defaultVisible && header.notForUser
      );
    } else {
      this.visibleHeaders = this.headers.filter(
        (header) => header.defaultVisible
      );
    }
    this.headers.forEach((header) => {
      if (this.isAdmin || this.isEngineer || this.isDtiSef) {
        return (header.visible = header.defaultVisible);
      } else {
        return (header.visible = header.defaultVisible && header.notForUser);
      }
    });
    this.fetchRequestData();
    this.$refs.deleteItem.$on("error", this.handleError);
    this.$refs.deleteItem.$on("success", this.handleSuccess);
    this.$refs.dialogForm.$on("error", this.handleError);
    this.$refs.dialogForm.$on("success", this.handleSuccess);
  },
};
</script>

<style scoped>
.requests .v-data-table::v-deep tbody td {
  font-size: 18px;
}
.v-data-table::v-deep th {
  font-size: 20px !important;
  background-color: #2d8659 !important;
  color: white !important;
  white-space: nowrap;
}
.light-green-row {
  background-color: #ecf9f2;
}
.red-row {
  background-color: #f29daa;
}
@media (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}
</style>
