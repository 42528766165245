<template>
  <div>
    <v-card>
      <v-subheader class="py-0 d-flex justify-space-between rounded-lg">
        <h3>{{ getTimelineTitle() }}</h3>
      </v-subheader>
      <v-timeline dense>
        <v-timeline-item
          v-for="(event, index) in timelineData"
          :key="index"
          :color="calculateColor(index)"
          small-dot
        >
          <template v-slot:icon>
            <v-icon>mdi-check</v-icon>
          </template>
          <v-row>
            <v-col>
              <span class="font-weight-bold text-lighten">{{ event.status }}</span>
              <div>
                <span class="font-weight-bold text-lighten">Comentarii:</span> {{ event.comments }}
              </div>
              <div>
                <span class="font-weight-bold text-lighten">Utilizator:</span> {{ event.user }}
              </div>
              <div>{{ event.date }}</div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    timelineData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    calculateColor(index) {
      const blue = 0;
      const green = 255;
      const colorValue =
        blue + (green - blue) * (index / this.timelineData.length);
      return `rgb(${colorValue}, ${green}, ${colorValue})`;
    },
    getTimelineTitle() {
      return (
        "Demers - Nr. " +
        (this.timelineData[0].number ? this.timelineData[0].number : "")
      );
    },
  },
};
</script>

<style scoped>
.font-weight-bold {
  font-weight: bold;
}

.text-lighten {
  color: #666;
}
</style>
