import axios from '@/store/interceptor';

import apiUrl from '../../config/api.config'

export async function getMicroserviceDepartments() {
    try {
        const response = await axios.get(`${apiUrl}/departments/microservice-departments`);
        return response.data;
    } catch (error) {
        console.error("Error fetching departments:", error);
        throw error;
    }
}

export async function getUsers() {
    try {
        const response = await axios.get(`${apiUrl}/users`);
        return response.data;
    } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
    }
}

export async function getUserById(personId) {
    try {
        const response = await axios.get(`${apiUrl}/users/${personId}/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
    }
}
export async function getDepartments() {
    try {
        const response = await axios.get(`${apiUrl}/departments`);
        return response.data;
    } catch (error) {
        console.error("Error fetching departments:", error);
        throw error;
    }
}

export async function fetchRoles() {
    try {
        const response = await axios.get(`${apiUrl}/roles`);
        return response.data;
    } catch (error) {
        console.error("Error fetching roles:", error);
        throw error;
    }
}

export async function getUserPhoneNumber(idnp) {
    try {
        const response = await axios.get(`${apiUrl}/users/contacts/${idnp}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching user phone number:", error);
        throw error;
    }
}

export async function getUsersTableData() {
    try {
        const response = await axios.get(`${apiUrl}/users/user-table-data`);
        return response.data;
    } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
    }
}

export async function saveUserData(postData) {
    try {
        const response = await axios.post(`${apiUrl}/users`, postData, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error saving user:", error);
        throw error;
    }
}

export async function changePassword(userId, oldPassword, newPassword) {
    try {
        const response = await axios.put(`${apiUrl}/users/${userId}/change-password`, {
            oldPassword,
            newPassword
        });
        return response.data;
    } catch (error) {
        console.error("Error changing password:", error);
        throw error;
    }
}

export async function generateNewPassword(userId, oldPassword, newPassword) {
    try {
        const response = await axios.put(`${apiUrl}/users/${userId}/generate-new-password`);
        return response.data;
    } catch (error) {
        console.error("Error changing password:", error);
        throw error;
    }
}
export async function updateUser(userData) {
    try {
        const response = await axios.patch(`${apiUrl}/users/${userData.id}`, userData, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error updating user:", error);
        throw error;
    }
}

export async function deleteUser(userId) {
    try {
        const response = await axios.delete(`${apiUrl}/users/${userId}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting user:", error);
        throw error;
    }
}

