<template>
  <v-dialog v-model="showEditPopup" persistent width="1024">
    <v-card>
      <v-card-title>
        Editează Utilizatorul
        <v-spacer></v-spacer>
        <v-btn icon dark color="black" @click="showEditPopup = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="updateUser">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="selectedRole"
                  :items="roles"
                  label="Rol*"
                  item-text="role"
                  item-value="id"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="selectedDepartment"
                  :items="departments"
                  label="Departamentul Șefului*"
                  item-text="Denumire"
                  item-value="Denumire"
                  :error-messages="formErrors.selectedDepartment"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="userName"
                  label="Login*"
                  required
                  :error-messages="formErrors.userName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="firstName"
                  label="Numele"
                  required
                  :error-messages="formErrors.firstName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="lastName"
                  label="Prenumele"
                  required
                  :error-messages="formErrors.lastName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="email"
                  label="Email*"
                  required
                  :error-messages="formErrors.email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="telephone"
                  label="Telefon mobil"
                  :counter="9"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="landline"
                  label="Telefon fix*"
                  required
                  :error-messages="formErrors.landline"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox v-model="isActive" label="Este Activ"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-btn
              v-if="
                this.getUser.role !== 'inginer' &&
                this.getUser.role !== 'dtisef'
              "
              style="color: white; background-color: #2d8654"
              variant="text"
              @click="showConfirmCard()"
            >
              Parolă nouă
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              style="color: white; background-color: #2d8654"
              variant="text"
              >Actualizare</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
      <ConfirmCard
        v-if="confirmCard"
        title="Doriți să generați o parolă nouă?"
        @cancel="cancelConfirmCard"
        @confirm="newPassword"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import * as api from "./api";
import * as helper from "@/helper/helper.js";
import ConfirmCard from "@/components/elements/ConfirmCard.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ConfirmCard,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      roles: [],
      departments: [],
      showEditPopup: false,
      selectedRole: null,
      selectedDepartment: null,
      userName: "",
      firstName: "",
      lastName: "",
      editedUser: { ...this.user },
      email: "",
      isActive: false,
      disabled: false,
      landline: "",
      telephone: "",
      confirmCard: false,
      formErrors: {
        selectedRole: "",
        selectedDepartment: "",
        userName: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        landline: "",
        telephone: "",
      },
    };
  },
  methods: {
    show() {
      this.showEditPopup = true;

      this.fetchData();
    },
    async fetchData() {
  try {
    this.roles = await api.fetchRoles();

    const allDepartments = await api.getDepartments();
    this.departments = allDepartments
      .filter(department => department.parent_id === 1)
      .map(dept => dept.name);

    if (this.user && this.user.department) {
      this.selectedDepartment = this.user.department;
    }

  } catch (error) {
    console.error("Error fetching data:", error);
    this.$emit("error", error);
  }
},


    async updateUser() {
      this.validateForm();

      if (!this.isFormValid()) {
        return;
      }

      const formattedLandline = this.validateLandline(this.landline);
      if (!formattedLandline) {
        this.formErrors.landline = "Telefon fix invalid";
        return;
      }

      const userDataToUpdate = {
        id: this.editedUser.id,  
        role_id: this.selectedRole,
        user_name: this.userName,
        first_name: this.firstName,
        last_name: this.lastName,
        department: this.selectedDepartment,
        email: this.email,
        isActive: this.isActive,
        landline: formattedLandline,
        telephone: this.telephone,
      };

      // console.log("Prepared userDataToUpdate object:", userDataToUpdate);

      if (!userDataToUpdate.id) {
          console.error("No user ID found for updating. userDataToUpdate:", userDataToUpdate);
          return;  // Early exit if ID is undefined
      }

      try {
        await api.updateUser(userDataToUpdate);
        this.showEditPopup = false;
        this.$emit("success", "Editare cu success");
        this.$emit("updateUserData");
      } catch (error) {
        console.error("Error updating user:", error);
        this.showEditPopup = false;
        this.$emit("error", error);
      }
    },
    resetForm() {
      this.formErrors = {
        selectedRole: "",
        selectedDepartment: "",
        userName: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        landline: "",
        telephone: "",
      };
    },
    validateForm() {
      this.formErrors.selectedRole = this.selectedRole
        ? ""
        : "Selectați un rol";
      this.formErrors.userName = this.userName
        ? ""
        : "Username este obligatoriu";
      this.formErrors.firstName = this.firstName
        ? ""
        : "Numele este obligatoriu";
      this.formErrors.lastName = this.lastName
        ? ""
        : "Prenumele este obligatoriu";
      if (this.selectedRole !== 2) {
        this.formErrors.selectedDepartment = this.selectedDepartment
          ? ""
          : "Departamentul este obligatoriu pentru șefii de departamente";
      }
      this.formErrors.email = this.validateEmail(this.email)
        ? ""
        : "Adresa de email invalidă";
    },
    validateEmail(email) {
      const isValidEmail = helper.validateEmail(email);
      return isValidEmail;
    },

    validateLandline(landline) {
      return helper.validateLandline(landline);
    },
    isFormValid() {
      return !Object.values(this.formErrors).some((error) => error);
    },
    cancelConfirmCard() {
      this.confirmCard = false;
    },
    showConfirmCard() {
      this.confirmCard = true;
    },
    async newPassword() {
      try {
        await api.generateNewPassword(this.user.id);
        this.$emit(
          "success",
          "Editarea parolei cu succes. Parola a fost trimisă utilizatorului pe poșta corparativă"
        );
        this.confirmCard = false;
        this.showEditPopup = false;
      } catch {
        console.error("Error updating user:", error);
        this.showEditPopup = false;
        this.$emit("error", error);
      }
    },
  },
  watch: {
    showEditPopup(newVal) {
      if (!newVal) {
        this.resetForm();
      }
    },
    user: {
      immediate: true,
      handler(newValue) {
        if (newValue && Object.keys(newValue).length > 0) {  
          // console.log("Received new user data:", newValue);
          this.editedUser = { ...newValue };
          // console.log("Edited user data initialized:", this.editedUser);

          this.selectedRole = this.roles.find(
            (role) => String(role.id) === String(newValue.roleId.id)
          );
          this.selectedRole = this.selectedRole.id;
          this.selectedDepartment = this.departments.find(
            (department) =>
              String(department.Denumire) === String(newValue.department)
          );
          this.userName = newValue.user_name;
          this.firstName = newValue.first_name;
          this.lastName = newValue.last_name;
          this.email = newValue.email;
          this.isActive = newValue.isActive === "Activ" ? true : false;
          this.telephone = newValue.telephone;
          this.landline = newValue.landline;
        }
      },
    },
    selectedRole(newValue) {
      if (newValue === 2) {
        this.formErrors.selectedDepartment = "";
        this.selectedDepartment = null;
      }
      if (newValue) {
        this.editedUser.role_id = newValue;
        this.formErrors.selectedRole = "";
      } else {
        this.editedUser.role_id = null;
      }
    },
    userName(newVal) {
      this.formErrors.userName = "";
    },
    firstName(newVal) {
      this.formErrors.firstName = "";
    },
    lastName(newVal) {
      this.formErrors.lastName = "";
    },
    selectedDepartment(newVal) {
      this.formErrors.selectedDepartment = "";
    },
    email(newVal) {
      this.formErrors.email = "";
    },
    telephone(newVal) {
      this.formErrors.telephone = "";
    },
    landline(newVal) {
      this.formErrors.landline = "";
    },
  },
  computed: {
    ...mapGetters(["getUser", "isAdmin"]),
  },
  mounted() {
this.editedUser = { ...this.user };
    this.fetchData();
  },
};
</script>
