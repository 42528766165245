<template>
  <v-row justify="end">
    <v-dialog v-model="dialog" persistent width="1024">
      <template v-slot:activator="{ props }">
        <v-btn
          x-large
          v-bind="props"
          @click="dialog = true"
          style="color: white; background-color: #2d8654"
        >
          Creare Demers
        </v-btn>
      </template>
      <v-card>
        <v-form fast-fail @submit.prevent="saveRequest">
          <v-card-title>
            <span class="text-h5">Creare Demers</span>
            <v-spacer></v-spacer>
            <v-btn icon dark color="black" @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="selectedEquipment"
                    :items="equipmentTypes"
                    label="Tip Echipament*"
                    item-text="type"
                    item-value="id"
                    required
                    @change="fetchModels"
                    :error-messages="formErrors.selectedEquipment"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    v-model="selectedModel"
                    :items="models"
                    label="Model*"
                    item-text="model"
                    item-value="model"
                    required
                    @change="fetchRegistrationNr"
                    :error-messages="formErrors.selectedModel"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    v-model="selectedRegistrationNr"
                    :items="registrations"
                    label="Nr. Inventar*"
                    item-text="registration_number"
                    item-value="registration_number"
                    required
                    :error-messages="formErrors.selectedRegistrationNr"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="selectedDepartment"
                    :items="departments"
                    label="Departamentul în care lucrați*"
                    item-text="Denumire"
                    item-value="Denumire"
                    required
                    :error-messages="formErrors.selectedDepartment"
                  ></v-autocomplete>
                </v-col>
                <!-- <v-col cols="12" md="2">
                  <v-checkbox
                    v-model="allDepartments"
                    label="Toate Departamentele"
                  ></v-checkbox>
                </v-col> -->
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="selectedRequest"
                    :items="requestTypes"
                    label="Tip cerere*"
                    item-text="type"
                    item-value="id"
                    required
                    :error-messages="formErrors.selectedRequest"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="body"
                    label="Comentarii"
                    persistent-hint
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <small>*câmpurile obligatorii</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="color: white; background-color: #2d8654"
              variant="text"
              type="submit"
            >
              Salvează
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import * as api from "./api";
import * as helper from "@/helper/helper";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      equipmentTypes: [],
      requestTypes: [],
      models: [],
      registrations: [],
      departments: [],
      selectedDepartment: null,
      selectedEquipment: null,
      selectedRequest: null,
      selectedModel: null,
      selectedRegistrationNr: null,
      responsiblePersonId: null, // Add this to store the correct responsible person ID
      body: "",
      message: "",
      formErrors: {
        selectedEquipment: "",
        selectedModel: "",
        selectedRegistrationNr: "",
        selectedDepartment: "",
        selectedRequest: "",
        body: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    async fetchData() {
      try {
        this.equipmentTypes = await api.fetchEquipmentTypes();
        this.requestTypes = await api.fetchRequestTypes();
        this.userData = await api.getUserById(this.getUser.userId);

        const allDepartments = await api.getDepartments();
        this.departments = allDepartments.map(dept => dept.name);

        this.selectedDepartment = this.userData.department;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.$emit("error", error);
      }
    },
    async saveRequest() {
      this.validateForm();

      if (!this.isFormValid()) {
        return;
      }

      const equipmentId = await api.getEquipmentIdByDetails(
        this.selectedEquipment,
        this.responsiblePersonId, // Use the correct responsible person ID here
        this.selectedModel,
        this.selectedRegistrationNr
      );

      if (!equipmentId) {
        this.$emit("error", "Echipamentul nu a fost găsit!");
        return;
      }

      const equipmentInfo = await api.getEquipmentById(equipmentId);

      if (!equipmentInfo) {
        this.$emit("error", "Informațiile despre echipament nu au fost găsite!");
        return;
      }

      if (!equipmentInfo.isFunctional) {
        this.dialog = false;
        this.$emit("error", "Acest echipament nu este activ!");
        return;
      }

      const postData = {
        requests_type_id: this.selectedRequest,
        equipment_id: equipmentId,
        approach_number: null,
        current_etap: 1,
        created_by: this.getUser.userId,
        department: this.selectedDepartment,
        isFinished: false,
      };

      try {
        const response = await api.saveRequestData(postData);
        const postResponseData = {
          response_type_id: 1,
          request_id: response.id,
          created_by: this.getUser.userId,
          comments: this.body,
        };
        await api.saveResponseData(postResponseData);
        this.$emit("success", "Creare cu success");
        this.$emit("updateRequestData");
        this.dialog = false;
        this.clearFields();
      } catch (error) {
        console.error("Error saving user:", error);
        this.$emit("error", error);
      }
    },
    async fetchModels() {
      if (this.selectedEquipment) {
        try {
          const modelsResponse = await api.getEquipmentModels(
            this.getUser.userId,
            this.selectedEquipment
          );

          if (modelsResponse.length === 0) {
            this.formErrors.selectedModel = "Nu există modele disponibile. Creați acest Echipament";
            this.formErrors.selectedRegistrationNr = "Nu există numar de itinerar pentru acest echipament";
            this.models = [];
            this.registrations = [];
          } else {
            this.models = modelsResponse;
            this.responsiblePersonId = modelsResponse[0]?.responsible_person_id || null; // Store the responsible person ID
            this.formErrors.selectedModel = "";
            this.formErrors.selectedRegistrationNr = "";
          }
        } catch (error) {
          console.error("Error fetching models:", error);
          this.$emit("error", error);
        }
      } else {
        this.models = [];
        this.registrations = [];
      }
    },
    async fetchRegistrationNr() {
      if (this.selectedModel) {
        try {
          const registrations = await api.getEquipmentRegistrationNum(
            this.getUser.userId,
            this.selectedEquipment,
            this.selectedModel
          );
          this.registrations = registrations;
          this.responsiblePersonId = registrations[0]?.responsible_person_id || null; // Store the responsible person ID
          this.formErrors.selectedModel = "";
          this.formErrors.selectedRegistrationNr = "";
        } catch (error) {
          console.error("Error fetching registrations:", error);
          this.$emit("error", error);
        }
      } else {
        this.registrations = [];
      }
    },
    validateForm() {
      this.formErrors.selectedEquipment = this.selectedEquipment
        ? ""
        : "Selectați un echipament";
      this.formErrors.selectedModel = this.selectedModel
        ? ""
        : "Selectați un model";
      this.formErrors.selectedRegistrationNr = this.selectedRegistrationNr
        ? ""
        : "Selectați un număr de înregistrare";
      this.formErrors.selectedDepartment = this.selectedDepartment
        ? ""
        : "Selectați un departament";
      this.formErrors.selectedRequest = this.selectedRequest
        ? ""
        : "Selectați un tip de cerere";
    },
    isFormValid() {
      return !Object.values(this.formErrors).some((error) => error);
    },
    closeDialog() {
      this.dialog = false;
      this.clearFields();
    },
    clearFields() {
      this.formErrors = {
        selectedEquipment: "",
        selectedModel: "",
        selectedRegistrationNr: "",
        selectedDepartment: "",
        selectedRequest: "",
        body: "",
      };
      this.selectedEquipment = null;
      this.selectedModel = null;
      this.selectedRegistrationNr = null;
      this.selectedDepartment = null;
      this.selectedRequest = null;
      this.body = "";
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    selectedEquipment(newVal) {
      this.formErrors.selectedEquipment = "";
    },
    selectedModel(newVal) {
      this.formErrors.selectedModel = "";
    },
    selectedRegistrationNr(newVal) {
      this.formErrors.selectedRegistrationNr = "";
    },
    selectedDepartment(newVal) {
      this.formErrors.selectedDepartment = "";
    },
    selectedRequest(newVal) {
      this.formErrors.selectedRequest = "";
    },
    body(newVal) {
      this.formErrors.body = "";
    },
  },
};
</script>
