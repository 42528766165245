<template>
  <div>
    <v-app-bar app elevate-on-scroll elevation="3" color="white">
      <v-app-bar-nav-icon @click="$emit('drawerEvent')"></v-app-bar-nav-icon>
      <v-spacer />
      <v-spacer />
      <ErrorAlert :message="errorMessage" @clear-error="clearMessage" />
      <SuccessAlert :message="successMessage" @clear-error="clearMessage" />
      <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <span style="cursor: pointer" v-bind="attrs" v-on="on">
            <v-chip link>
              <v-badge dot bottom color="green" offset-y="10" offset-x="10">
                <v-avatar size="40">
                  <v-img :src="myImage" />
                </v-avatar>
              </v-badge>
              <span class="ml-3 custom-font-size">
                {{ user && user.firstName }} {{ user && user.lastName }}
              </span>
            </v-chip>
          </span>
        </template>
        <v-list width="250" class="py-0">
          <v-list-item two-line>
            <v-list-item-avatar>
              <img :src="myImage" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="custom-font-size">
                {{ user && user.firstName }} {{ user && user.lastName }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="isLoggedIn"
                >Autentificat</v-list-item-subtitle
              >
              <v-list-item-subtitle v-else>Neautorizat</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item
            link
            v-for="(menu, i) in menus"
            :key="i"
            @click="handleMenuItemClick(menu)"
          >
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="custom-font-size">
              {{ menu.title }}
            </v-list-item-title>
          </v-list-item><v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-switch
                v-model="notificationsEnabled"
                @change="toggleNotifications"
                label="Notificările Windows"
              ></v-switch>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import menusData from "./menus.json";
import * as helper from "@/helper/helper";
import SuccessAlert from "@/components/alerts/SuccessAlert.vue";
import ErrorAlert from "@/components/alerts/ErrorAlert.vue";

export default {
  name: "TopBar",
  components: {
  SuccessAlert,
  ErrorAlert
},
  data() {
    return {
      menus: menusData,
      myImage: require("@/assets/pictures/dti.jpg"),
      notificationsEnabled: Notification.permission === "granted",
      errorMessage: "",
      successMessage: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    }
  },
  methods: {
    async toggleNotifications() {
      if (Notification.permission === "denied") {
        const newPermission = await helper.requestNotificationPermission();
        if (newPermission === "granted") {
          this.notificationsEnabled = true;
          this.handleSuccess("Notificările au fost activate");
        } else {
          this.notificationsEnabled = false;
          this.handleError("Notificările sunt blocate. Puteți debloca din setările browser-ului din chrome://settings/content");
        }
        return;
      }
      if (Notification.permission === "granted") {
        this.notificationsEnabled = true;
        this.handleError("Notificările sunt activate. Puteți bloca din setările browser-ului din chrome://settings/content");
        return;
      }
      if (Notification.permission !== "granted") {
        await helper.requestNotificationPermission();
        this.notificationsEnabled = Notification.permission === "granted";
        Notification.permission === "granted" ? this.handleSuccess("Notificările au fost activate") : this.handleError("Notificările vor fi blocate. Puteți debloca din setările browser-ului");
      }
    },
    handleMenuItemClick(menu) {
      if (menu.title === "Ieșire") {
        this.$store.dispatch("logout");
        this.$emit("logout");
      }
      if (menu.title === "Contul meu") {
        this.$router.push({ name: "profile" });
      }
    },
    handleError(message) {
      this.errorMessage = message;
    },
    handleSuccess(message) {
      this.successMessage = message;
    },
    clearMessage() {
      this.errorMessage = "";
      this.successMessage = "";
    },
  }
};
</script>

<style scoped>
.custom-font-size {
  font-size: 20px;
}
</style>
