<template>
  <div class="history">
    <v-row>
      <v-col cols="12" md="9">
        <v-card>
          <v-card-title class="d-flex align-center justify-end pe-2">
            <v-spacer></v-spacer>
            <SearchBar @search="handleSearch" />
            <v-spacer></v-spacer>
            <ColumnSelect
              :headers="headers"
              :updateVisibleColumns="updateVisibleColumns"
            />
          </v-card-title>
          <v-data-table
            :mobile-breakpoint="0"
            :headers="visibleHeaders"
            :items="filteredData"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            class="elevation-1"
            :loading="isLoading"
            loading-text="Incărcare... Aștepta-ți vă rog"
          >
            <template v-slot:item="{ item, index }">
              <tr
                :class="{
                  'light-green-row': index % 2 !== 0,
                  'red-row': item.status === 'Respins',
                }"
                @click="onRowClick(item)"
              >
                <td
                  v-for="(header, hIndex) in visibleHeaders"
                  :key="hIndex"
                  :style="header.style ? header.style : {}"
                  :class="{ 'red-row': item.status === 'Respins' }"
                >
                  {{ getTableCellValue(item, header, index) }}
                  <div v-if="header.value === 'actions'">
                    <v-btn
                      color="success"
                      outlined
                      small
                      shaped
                      @click="onViewClick(item)"
                      >Info</v-btn
                    >
                    <!-- <v-icon size="big" @click="onViewClick(item)">
                    mdi-eye
                  </v-icon> -->
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <RequestsTimeline
          :timelineData="selectedItemTimeline"
          v-if="selectedItem"
        />
      </v-col>
    </v-row>
    <HistoryDetails 
      ref="historyDetails"
      v-if="showHistoryDetails" 
      :item="selectedItem" 
      @updateRequestData="fetchHistoryData" 
      @closeDialog="closeHistoryDetails"
    />
  </div>
</template>

<script>
import RequestsTimeline from "@/components/requests/RequestsTimeline";
import TitleComponent from "@/components/elements/TitleComponent.vue";
import ColumnSelect from "@/components/elements/ColumnSelect.vue";
import SearchBar from "@/components/elements/SearchBar.vue";
import HistoryDetails from "./HistoryDetails.vue";
import * as api from "./api";
import * as helper from "@/helper/helper.js";
import { mapGetters } from "vuex";

export default {
  name: "HistoryPage",
  components: {
    RequestsTimeline,
    TitleComponent,
    ColumnSelect,
    SearchBar,
    HistoryDetails,
  },
  data() {
    return {
      headers: [],
      historyData: [],
      approvalData: [],
      visibleHeaders: [],
      selectedItem: null,
      selectedItemTimeline: [],
      search: "",
      sortBy: "id",
      sortDesc: true,
      isLoading: true,
      showHistoryDetails: false,
    };
  },
  methods: {
    async fetchHistoryData() {
      try {
        if (this.isAdmin || this.isEngineer || this.isDtiSef || this.isRector || this.isContability) {
          const historyResponse = await api.getHistoryRequests();

          this.historyData = historyResponse;
        } else {
          const historyResponse = await api.getHistoryRequestsByUser(
            this.getUser.userId
          );
          this.historyData = historyResponse;
        }

        let isFirstRequest = true;
        for (const request of this.historyData) {
          try {
            if (isFirstRequest && !this.selectedItem) {
              const timelineData = await api.getRequestTimelineData(request.id);
              request.timeline = timelineData;
              this.selectedItem = request;
              this.generateTimelineData(request);
              isFirstRequest = false;
            }
          } catch (error) {
            console.error(
              `Error fetching data for request ${request.id}:`,
              error
            );
          }
        }

        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    onRowClick(item) {
      this.onClick(item);
    },
    async onClick(item) {
      this.selectedItem = item;
      if (!item.timeline) {
        try {
          const timelineData = await api.getRequestTimelineData(item.id);
          item.timeline = timelineData;
          this.generateTimelineData(item);
        } catch (error) {
          console.error(
            `Error fetching timeline data for request ${item.id}:`,
            error
          );
        }
      } else {
        this.generateTimelineData(item);
      }
    },
    onViewClick(item) {
      // this.$router.push({ name: "historyDetails", params: { item: item } });
      this.selectedItem = item;
      this.showHistoryDetails = true;
      // this.$router.push({ 
      //   name: "historyDetails", 
      //   params: { 
      //     id: item.id, 
      //     item: item 
      //   }
      // });
    },
    generateTimelineData(item) {
      const timelineEvents =
        (item.timeline &&
          [...item.timeline].reverse().map((event) => ({
            number: event.approach_number,
            status: event.status,
            label: event.comments,
            date: event.date,
            user: event.user,
	    comments: event.comments
          }))) ||
        [];
      this.selectedItemTimeline = timelineEvents;
    },
    getTableCellValue(item, header, index) {
      if (header.value === "index") return index + 1;
      if (header.value === "user") return item.user;
      if (header.value === "approach_number")
        return item.approach_number != null ? item.approach_number : "";
      if (header.value === "status") return item.status;
      if (header.value === "equipment") return item.equipment;
      if (header.value === "model") return item.model;
      if (header.value === "registration_number")
        return item.registration_number;
      if (header.value === "created_at") return item.created_at;
      return "";
    },
    sortTable(header) {
      const result = helper.sortTable(header);
      return result;
    },
    updateVisibleColumns() {
      this.visibleHeaders = this.headers.filter((header) => header.visible);
    },
    handleSearch(searchTerm) {
      this.search = searchTerm;
    },
    closeHistoryDetails() {
    this.showHistoryDetails = false;
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    isAdmin() {
      return this.$store.getters.getUser.role === "admin";
    },
    isContability() {
      return this.$store.getters.getUser.role === "contability";
    },
    isEngineer() {
      return this.$store.getters.getUser.role === "inginer";
    },
    isDtiSef() {
      return this.$store.getters.getUser.role === "dtisef";
    },
    isRector() {
      return this.$store.getters.getUser.role === "rector";
    },
    filteredData() {
      const result = helper.filteredData(this.historyData, this.search);
      return result;
    },
  },
  mounted() {
    this.headers = require("./historyData.json");
    this.visibleHeaders = this.headers.filter(
      (header) => header.defaultVisible
    );
    this.headers.forEach((header) => {
      header.visible = header.defaultVisible;
    });
    this.fetchHistoryData();
  },
};
</script>

<style scoped>
.history .v-data-table::v-deep tbody td {
  font-size: 18px;
}
.v-data-table::v-deep th {
  font-size: 20px !important;
  background-color: #2d8659 !important;
  color: white !important;
  white-space: nowrap;
}
.light-green-row {
  background-color: #ecf9f2;
}
.red-row {
  background-color: #f29daa;
}
@media (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}
</style>
