<template>
  <v-text-field
    v-model="search"
    prepend-inner-icon="mdi-magnify"
    density="compact"
    label="Cauta dupa toate campurile"
    single-line
    flat
    hide-details
    variant="solo-filled"
  ></v-text-field>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
  watch: {
    search: function (newSearchValue) {
      this.$emit("search", newSearchValue);
    },
  },
};
</script>

<style scoped></style>
