<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <DeleteCard
      title="Doriți să ștergeți acest demers cu toate înregistrările?"
      @cancel="cancelDelete"
      @confirm="confirmDelete"
    />
  </v-dialog>
</template>

<script>
import { deleteRequest } from "./api.js";
import DeleteCard from "@/components/elements/DeleteCard.vue";

export default {
  props: {
    request: Object,
  },
  components: {
    DeleteCard,
  },
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    show() {
      this.dialogDelete = true;
    },
    cancelDelete() {
      this.dialogDelete = false;
    },
    async confirmDelete() {
      try {
        await deleteRequest(this.request.id);
        this.$emit("updateRequestData");
        this.$emit("success", "Ștergere cu success");
        this.dialogDelete = false;
      } catch (error) {
        console.error("Error deleting request:", error);
        this.$emit("error", error);
      }
    },
  },
};
</script>
