<template>
  <v-navigation-drawer
    :value="drawer"
    app
    @input="$emit('update:drawer', $event)"
  >
    <v-img height="140" class="pa-4" :src="myImage"> </v-img>
    <v-divider></v-divider>
    <v-list>
      <template v-for="link in filteredLinks">
        <v-list-item
          v-if="canAccessLink(link)"
          :key="link.icon"
          link
          :to="link.url"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="custom-font-size">{{
              link.label
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import linksData from "./links.json";

export default {
  name: "SideBar",
  props: ["drawer"],
  data() {
    return {
      adminPanelOpen: false,
      links: linksData,
      myImage: require("@/assets/pictures/usarb.jpg"),
    };
  },
  computed: {
    userRole() {
      return this.$store.getters.getUser.role;
    },
    isAdmin() {
      return this.$store.getters.getUser.role === "admin";
    },
    isEngineer() {
      return this.$store.getters.getUser.role === "inginer";
    },
    filteredLinks() {
      return this.links.filter((link) => this.canAccessLink(link));
    },
  },
  methods: {
    requiresAdmin(link) {
      return link.meta && link.meta.requiresAdmin;
    },
    forUser(link) {
      return link.meta && link.meta.forUser;
    },
    canAccessLink(link) {
      if (link.meta && link.meta.roles) {
        return link.meta.roles.includes(this.userRole);
      }
      return true;
    },
  },
};
</script>

<style scoped>
.custom-font-size {
  font-size: 20px;
}
</style>
