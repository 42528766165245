<template>
  <v-container fluid>
    <v-toolbar color="#2d8659" dark class="toolbar-fixed">
      <v-toolbar-title class="toolbar-content">
        <img
          src="@/assets/pictures/LogoUsarb.png"
          alt="Logo"
          class="toolbar-logo"
        />
        <span class="toolbar-text">
          Universitatea de Stat <br />
          Alecu Russo din Bălți
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-row justify="center" align="center" class="login-row">
      <ErrorAlert :message="form.errorMessage" @clear-error="clearMessage" />
      <v-col cols="12" sm="8" md="6" lg="8">
        <div class="contact-info">
          <h3>Bun venit în aplicația <b>Support</b></h3>
          <p>
            Accesul este permis doar persoanelor contul cărora a fost creat de
            către <b>Direcția Tehnologii Informaționale</b>
          </p>
          <h3>Adresa noastră:</h3>
          <p>
            Campusul Universitar, Blocul 3, etajul 3, of. 366,
            <b>tel.</b> +373(231) 52-388, <b>e-mail:</b> operator.dti@usarb.md
          </p>
        </div>
        <div class="text-center">
          <h2 class="welcome-text">Autentificare</h2>
        </div>
        <v-card class="mx-auto" max-width="400">
          <v-card-text>
            <v-form @submit.prevent="login">
              <v-text-field
                v-model="form.username"
                label="Login"
                prepend-inner-icon="mdi-account"
                required
                :error-messages="formErrors.username"
                @keydown.enter="login"
              />
              <v-text-field
                v-model="form.password"
                :type="showPassword ? 'text' : 'password'"
                label="Parola"
                prepend-inner-icon="mdi-key"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                required
                :error-messages="formErrors.password"
                @keydown.enter="login"
              />
              <v-card-actions class="justify-end">
                <v-spacer></v-spacer>
                <v-btn color="#2d8659" class="white--text" @click="login">
                  Logare
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import { loginUser } from "./api";
import { jwtDecode } from "jwt-decode";
import { reactive } from "vue";
import ErrorAlert from "@/components/alerts/ErrorAlert.vue";

export default {
  components: {
    ErrorAlert,
  },
  data() {
    return {
      showPassword: false,
      form: reactive({
        username: "",
        password: "",
        errorMessage: "",
      }),
      formErrors: reactive({
        username: "",
        password: "",
      }),
    };
  },
  methods: {
    async login() {
      this.validateForm();

      if (!this.form.username || !this.form.password) {
        return;
      }
      const credentials = {
        user_name: this.form.username,
        password: this.form.password,
      };
      try {
        const response = await loginUser(credentials);
        const token = response.access_token;
        console.log("\n", token);
        const data = jwtDecode(token);
        localStorage.setItem("token", token);
        this.$store.commit("SET_TOKEN", token);
        this.$store.commit("SET_USER", data);
        this.$router.push("/");
      } catch (error) {
        if (error.response) {
          this.form.password = "";
          this.handleError("Username sau parola incorectă");
        }
      }
    },
    validateForm() {
      this.formErrors.username = !this.form.username
        ? "Username este obligatoriu"
        : "";
      this.formErrors.password = !this.form.password
        ? "Parola este obligatorie"
        : "";
    },
    handleError(message) {
      this.form.errorMessage = message;
    },
    clearMessage() {
      this.form.errorMessage = "";
    },
  },
  watch: {
    "form.username"(newVal) {
      this.formErrors.username = "";
    },
    "form.password"(newVal) {
      this.formErrors.password = "";
    },
  },
};
</script>

<style scoped>
.toolbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
}
.toolbar-content {
  display: flex;
  align-items: center;
  margin-left: 100px;
}
.toolbar-logo {
  width: 70px;
  height: auto;
  margin-right: 10px;
}

.toolbar-text {
  font-size: 16px;
  font-weight: bold;
}

.login-row {
  height: calc(100vh - 250px);
}

.welcome-text {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 30px;
  color: #595959;
}

.error-message {
  color: red;
  margin-top: 5px;
}
.contact-info {
  margin-top: 20px;
  background-color: #d1edd8;
  border: 1px solid #2d8659;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 10px;
}

.contact-info h3,
.contact-info p {
  margin-bottom: 5px;
}

@media only screen and (max-width: 600px) {
  .login-row {
    height: calc(100vh - 250px);
  }
  .toolbar-content {
    margin-left: 10px;
  }
}
</style>
