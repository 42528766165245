<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <ErrorAlert :message="errorMessage" @clear-error="clearMessage" />
      <v-card-title>
        <span class="text-h5">Schimbă parola</span>
        <v-spacer></v-spacer>
        <v-btn icon dark color="black" @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="changePassword">
          <v-container class="form-container">
            <v-text-field
              v-model="oldPassword"
              label="Parola veche"
              :type="showOldPassword ? 'text' : 'password'"
              :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showOldPassword = !showOldPassword"
              :error-messages="formErrors.oldPassword"
            ></v-text-field>
            <v-text-field
              v-model="newPassword"
              label="Parola nouă"
              :type="showNewPassword ? 'text' : 'password'"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showNewPassword = !showNewPassword"
              :error-messages="formErrors.newPassword"
            ></v-text-field>
            <v-text-field
              v-model="confirmPassword"
              label="Confirmarea parolei noi"
              :type="showConfirmPassword ? 'text' : 'password'"
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showConfirmPassword = !showConfirmPassword"
              :error-messages="formErrors.confirmPassword"
            ></v-text-field>
          </v-container>
          <v-card-actions>
            <div class="error-message">{{ this.formErrors.errorMessage }}</div>
            <v-spacer></v-spacer>
            <v-btn style="color: white; background-color: #2d8654" type="submit"
              >Actualizare</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import * as api from "../../views/users/api.js";
import ErrorAlert from "@/components/alerts/ErrorAlert.vue";

export default {
  components: {
    ErrorAlert,
  },
  data() {
    return {
      dialog: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      errorMessage: "",
      formErrors: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        errorMessage: "",
      },
    };
  },
  methods: {
    show() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.clearFields();
    },
    clearFields() {
      this.formErrors = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        errorMessage: "",
      };
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";
    },

    async changePassword() {
      this.validateForm();

      if (!this.isFormValid()) {
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.formErrors.confirmPassword = "Parola nu corespunde";
        return;
      }
      try {
        await api.changePassword(
          this.$store.getters.getUser.userId,
          this.oldPassword,
          this.newPassword
        );
        this.$emit("password-changed");
        this.closeDialog();
      } catch (error) {
        this.formErrors.errorMessage = "Indicați corect parola veche";
        // this.$emit("error", "Indicați parola veche corect");
        this.handleError("Indicați corect parola veche");
        this.clearFields();
      }
    },
    validateForm() {
      this.formErrors.oldPassword = this.oldPassword
        ? ""
        : "Parola veche este obligatorie";
      this.formErrors.newPassword = this.newPassword
        ? ""
        : "Parola nouă este obligatorie";
      this.formErrors.confirmPassword = this.confirmPassword
        ? ""
        : "Confirmarea parolei este obligatorie";
    },
    isFormValid() {
      return !Object.values(this.formErrors).some((error) => error);
    },
    handleError(message) {
      this.errorMessage = message;
    },
    clearMessage() {
      this.errorMessage = "";
    },
  },
  watch: {
    oldPassword(newVal) {
      this.formErrors.oldPassword = "";
    },
    newPassword(newVal) {
      this.formErrors.newPassword = "";
    },
    confirmPassword(newVal) {
      this.formErrors.confirmPassword = "";
    },
    errorMessage(newVal) {
      this.formErrors.errorMessage = "";
    },
  },
};
</script>

<style scoped>
.form-container {
  max-width: 400px;
  margin: 0 auto;
}
.error-message {
  color: red;
  margin-top: 5px;
}
</style>
