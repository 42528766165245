<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay>
    <v-toolbar dark color="#2d8659">
      <v-btn icon dark @click="handleClose()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title
        >Istoria Demersului Nr. {{ approach_number }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card color="white">
      <div class="space"></div>
      <ErrorAlert :message="errorMessage" @clear-error="clearMessage" />
      <SuccessAlert :message="successMessage" @clear-error="clearMessage" />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <h3 class="custom-heading">Demers</h3>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="approach_number"
                label="Număr demers"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="created_at"
                label="Data emiterii"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="status"
                label="Starea curentă"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <h3 class="custom-heading">Utilizator</h3>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="user"
                label="Utilizatorul"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="userDepartment"
                label="Departament"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2">
              <v-text-field
                v-model="telephone"
                label="Telefon mobil"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2">
              <v-text-field
                v-model="landline"
                label="Telefon fix"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <h3 class="custom-heading">Echipament</h3>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="equipment"
                label="Echipamentul"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="model"
                label="Model"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="registration_number"
                label="Nr Inventar"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="equipmentdepartment"
                label="Departament"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="location"
                label="Locația"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-if="(this.getUser.role === 'inginer' || this.getUser.role === 'admin') && (this.item.status === 'În proces de lucrare' || this.item.status === 'Imposibil la moment')"
                v-model="price"
                label="Prețul deservirii"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-if="(this.getUser.role === 'inginer' || this.getUser.role === 'admin') && (this.item.status === 'În proces de lucrare' || this.item.status === 'Imposibil la moment')"
                v-model="body"
                label="Comentarii"
                persistent-hint
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-row justify="end">
            <v-btn
              v-if="(this.getUser.role === 'inginer' || this.getUser.role === 'admin') && (this.item.status === 'În proces de lucrare' || this.item.status === 'Imposibil la moment')"
              style="color: white; background-color: #2d8654"
              variant="text"
              @click="showConfirmCard('done')"
              class="mr-2 mt-2"
            >
              Îndeplinit
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card-text>
      <ConfirmCard
        v-if="confirmCard"
        :title="confirmTitle"
        @cancel="cancelConfirmCard"
        @confirm="confirmAction"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import * as api from "./api";
import { mapGetters } from "vuex";
import * as helper from "@/helper/helper.js";
import ErrorAlert from "@/components/alerts/ErrorAlert.vue";
import SuccessAlert from "@/components/alerts/SuccessAlert.vue";
import ConfirmCard from "@/components/elements/ConfirmCard.vue";

const approvalType = {
  DONE: 9,
};

export default {
  name: "HistoryDetails",
  props: ["item"],
  components: {
    ErrorAlert,
    SuccessAlert,
    ConfirmCard
  },
  data() {
    return {
      userInfo: [],
      dialog: true,
      mobilePhone: "",
      fixPhone: "",
      approach_number: "",
      created_at: "",
      status: "",
      user: "",
      userDepartment: "",
      equipment: "",
      model: "",
      registration_number: "",
      equipmentdepartment: "",
      location: "",
      price: null,
      body: "",
      errorMessage: "",
      successMessage: "",
      confirmCard: false,
      confirmTitle: "", 
      pendingAction: null, 
      telehone: "",
      landline: ""
    };
  },
  methods: {
    async fetchData() {
      try {
        if (!this.user) return;
        this.departments = [this.user.department];
        this.mobilePhone = this.user.telephone ? this.user.telephone : "Nu există";
        this.fixPhone = this.user.landline ? this.user.landline : "Nu există";
      } catch (error) {
        console.error("Error fetching user data:", error);
        this.$emit("error", error);
      }
    },
    async done() {
      console.log("aksjdhflk")
      const responseType =
        this.getUser.role === "inginer" ? approvalType.DONE : null;
      if (responseType !== null) {
        let postResponseData = {
        response_type_id: responseType,
        request_id: this.item.id,
        created_by: this.getUser.userId,
        comments: this.body,
        };

        let requestData = {
          id: this.item.id,
          current_etap: responseType,
          service_price: this.price,
        };

        try {
          await api.saveResponseData(postResponseData);
          await api.updateRequests(requestData);
          this.$emit("updateRequestData");
          // this.dialog = false;
          // this.$router.go(-1);
          this.handleSuccess("Editare cu success");
        } catch (error) {
          console.error("Error saving request:", error);
          // this.dialog = false;
          // this.$router.go(-1);
          this.handleError(error);
        }
      } else {
        this.dialog = false;
        this.handleError("Nu puteti efectua aceste modificari.");
      }
    },
    handleClose() {
      this.dialog = false;
      this.$emit("closeDialog");
    },
    onEscKey(event) {
      if (event.key === "Escape") {
        this.handleClose();
      }
    },
    async routeLeave() {
      this.$router.go(-1);
    },
    handleError(message) {
      this.errorMessage = message;
    },
    handleSuccess(message) {
      this.successMessage = message;
    },
    clearMessage() {
      this.errorMessage = "";
      this.successMessage = "";
    },
    showConfirmCard(action) {
      this.confirmCard = true;
      this.pendingAction = action;
      this.confirmTitle = "Doriți să continuați?";
    },
    confirmAction() {
      this.confirmCard = false;
      if (this.pendingAction) {
        this[this.pendingAction]();
      }
    },
    cancelConfirmCard() {
      this.confirmCard = false;
      this.pendingAction = null;
    },
  },
  computed: {
    ...mapGetters(["getUser", "isAdmin"]),
  },
  mounted() {
    if (!this.item) {
      this.routeLeave();
    }
    document.addEventListener("keydown", this.onEscKey);
    this.fetchData();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onEscKey);
  },
  watch: {
    item: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.approach_number =
            newValue.approach_number !== null
              ? newValue.approach_number
              : "Neaprobat";
          this.created_at = newValue.created_at;
          this.status = newValue.status;
          this.user = newValue.user;
          this.userDepartment = newValue.userDepartment;
          this.equipment = newValue.equipment;
          this.model = newValue.model;
          this.registration_number = newValue.registration_number;
          this.equipmentdepartment = newValue.equipmentdepartment;
          this.location = newValue.equipmentLocation;
          this.landline = newValue.landline;
          this.telephone = newValue.telephone;
        }
      },
    },
  },
};
</script>

<style scoped>
.form-container {
  background-color: #ffffff;
  padding: 20px;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.space {
  padding-top: 20px;
  background-color: white;
}
.error-message {
  color: red;
  align-items: center;
}
.custom-heading {
  margin-bottom: 0px;
  color: #424242;
  text-align: center;
}
</style>
