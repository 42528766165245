import axios from "axios";

import apiUrl from '../../config/api.config'

export async function loginUser(credentials) {
    try {
        const response = await axios.post(`${apiUrl}/auth/login`, credentials, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        //console.error("Error login the user:", error);
        throw error;
    }
}