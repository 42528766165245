<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn outlined v-on="on"
        ><template v-if="isMobile === false"> Coloane </template>
        <template v-else>
          <v-icon class="mobile-mode">mdi-view-column</v-icon>
        </template></v-btn
      >
    </template>
    <div class="columns-checkboxes">
      <v-checkbox
        v-for="header in headers"
        :key="header.value"
        v-model="header.visible"
        @click="updateVisibleColumns"
        :label="header.text"
      ></v-checkbox>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    headers: Array,
    updateVisibleColumns: Function,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = window.innerWidth < 600;
    window.addEventListener("resize", this.updateScreenWidth);
  },
  methods: {
    updateScreenWidth() {
      this.isMobile = window.innerWidth < 600;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenWidth);
  },
};
</script>

<style scoped>
.columns-checkboxes {
  display: grid;
  gap: 10px;
  background-color: white;
  grid-template-columns: auto auto;
}
@media (max-width: 600px) {
  .v-btn:not(.v-btn--round).v-size--default {
    height: 30px;
    min-width: 40px;
    padding: 0 0px;
  }
}
</style>
