<template>
  <v-app id="inspire">
    <template v-if="!isLoginPage && this.$store.getters.isLoggedIn">
      <SideBar :drawer="drawer" />
      <TopBar @drawerEvent="drawer = !drawer" @logout="handleLogout" />
    </template>
    <v-main style="background: #f5f5f540">
      <v-container class="py-8 px-6" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SideBar from "./common/Sidebar/SideBar";
import TopBar from "./common/Topbar/TopBar";

export default {
  name: "App",
  components: { TopBar, SideBar },
  data: () => ({
    drawer: null,
  }),
  computed: {
    isLoginPage() {
      return this.$route.name === "login";
    },
  },
  methods: {
    handleLogout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
    },
  },
};
</script>
