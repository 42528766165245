import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import Notifications from 'vue-notification';
import store from "../src/store/store";
import 'vuetify/dist/vuetify.min.css'
import io from 'socket.io-client';
import * as helper from "@/helper/helper";

Vue.use(Notifications);

Vue.config.productionTip = false;

const isDevelopmentMode = Number(process.env.VUE_APP_PROD_MODE) === 1;
let socket;
if (!isDevelopmentMode) {
  socket = io('http://192.168.254.157:3000', { // using ws (no SSL)
    transports: ['websocket', 'polling'],
    reconnectionAttempts: 5,
  });
} else {
  socket = io('https://suport.usarb.md', { // using wss (with SSL)
    transports: ['websocket', 'polling'],
    reconnectionAttempts: 5,
    secure: true, // Ensures the socket is secure (wss)
  });
}

Vue.prototype.$socket = socket;
// console.log(`Environment: ${isDevelopmentMode ? 'Development' : 'Production'}`);
// console.log(`Socket URL in use: ${socket.io.uri}`);

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
  mounted() {
    helper.setupWebSocket(this, this.$store.getters.getUser.role);
  }
}).$mount('#app');
