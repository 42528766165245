<template>
  <v-row justify="end">
    <v-dialog v-model="dialog" persistent width="1024">
      <template v-slot:activator="{ props }">
        <v-btn
          x-large
          v-bind="props"
          @click="dialog = true"
          style="color: white; background-color: #2d8654"
        >
          Creare Echipament
        </v-btn>
      </template>
      <v-card>
        <v-form @submit.prevent="saveEquipment">
          <v-card-title>
            <span class="text-h5">Creare Echipament</span>
            <v-spacer></v-spacer>
            <v-btn icon dark color="black" @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col v-if="isAdmin" cols="12" sm="5">
                  <v-autocomplete
                    v-model="selectedUser"
                    :items="users"
                    label="Persoana Responsabila*"
                    item-text="user"
                    item-value="id"
                    required
                    :error-messages="formErrors.selectedUser"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="selectedType"
                    :items="types"
                    label="Tip echipament*"
                    item-text="type"
                    item-value="id"
                    required
                    :error-messages="formErrors.selectedType"
                  ></v-select>
                </v-col>
                <!-- <v-col cols="4" md="2">
                  <v-checkbox
                    v-model="isFunctional"
                    label="Este activ"
                  ></v-checkbox>
                </v-col> -->
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="model"
                    label="Model*"
                    hint="exemplu: Samsung ML 2165"
                    required
                    :error-messages="formErrors.model"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="registration"
                    label="Nr. Inventar (serial number)*"
                    hint="exemplu: KB5Z190549"
                    required
                    :error-messages="formErrors.registration"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-select
                    v-model="selectedBlock"
                    :items="blocks"
                    label="Selectează Blocul*"
                    item-text="block"
                    item-value="block"
                    required
                    @change="fetchFloors"
                    :error-messages="formErrors.selectedBlock"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-select
                    v-model="selectedFloor"
                    :items="floors"
                    label="Selectează etajul*"
                    item-text="floor"
                    item-value="floor"
                    required
                    @change="fetchRooms"
                    :error-messages="formErrors.selectedFloor"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-select
                    v-model="selectedRoom"
                    :items="rooms"
                    label="Selectează aula*"
                    item-text="room"
                    item-value="room"
                    required
                    :error-messages="formErrors.selectedRoom"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    style="color: white; background-color: #2d8654"
                    @click="resetDepartment"
                    >Resetează Dep.</v-btn
                  >
                  <v-treeview
                    v-model="selectedDepartment"
                    :items="departmentTree"
                    item-key="id"
                    item-text="name"
                    selectable
                    return-object
                    dense
                    open-on-click
                    label="Select Department*"
                    :disabled="isTreeviewDisabled"
                  ></v-treeview>
                  <span class="error-message red--text">
                    {{ formErrors.selectedDepartment }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
            <small>*câmpurile obligatorii</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="color: white; background-color: #2d8654"
              variant="text"
              type="submit"
            >
              Salvează
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import * as api from "./api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      types: [],
      users: [],
      departments: [],
      blocks: [],
      floors: [],
      rooms: [],
      model: "",
      registration: "",
      location: "",
      selectedType: null,
      selectedUser: null,
      // isFunctional: true,
      selectedDepartment: null,
      selectedBlock: null,
      selectedFloor: null,
      selectedRoom: null,
      isTreeviewDisabled: false,
      formErrors: {
        selectedUser: "",
        selectedType: "",
        model: "",
        registration: "",
        registration: "",
        selectedBlock: "",
        selectedFloor: "",
        selectedRoom: "",
        selectedDepartment: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getUser", "isAdmin"]),
    departmentTree() {
      if (this.departments && this.departments.length > 0) {
        return this.convertToTree(this.departments);
      } else {
        return [];
      }
    },
  },
  methods: {
    async fetchData() {
      try {
        this.types = await api.fetchEquipmentTypes();
        this.users = await api.getUsersDropDown();
        this.departments = await api.getDepartments();
        this.blocks = await api.fetchBlocks();
      } catch (error) {
        console.error("Error fetching:", error);
        this.$emit("error", error);
      }
    },
    resetDepartment() {
      this.selectedDepartment = [];
      this.isTreeviewDisabled = false;
      this.formErrors.selectedDepartment = "";
    },
    async saveEquipment() {
      this.validateForm();

      if (!this.isFormValid()) {
        return;
      }

      const locationId = await api.getLocationIdByDetails(
        this.selectedBlock,
        this.selectedFloor,
        this.selectedRoom
      );

      const postData = {
        equipment_type_id: this.selectedType,
        model: this.model,
        registration_number: this.registration,
        responsible_person_id: this.isAdmin
          ? this.selectedUser
          : this.getUser.userId,
        location_id: locationId,
        department_id:
          this.selectedDepartment.length > 0
            ? this.selectedDepartment[0].id
            : null,
        // isFunctional: this.isFunctional,
      };

      try {
        await api.saveEquipmentData(postData);
        this.$emit("updateEquipmentData");
        this.$emit("success", "Creare cu success");
        this.dialog = false;
        this.clearFields();
      } catch (error) {
        console.error("Error saving user:", error);
        this.$emit("error", error);
      }
    },
    async fetchFloors() {
      if (this.selectedBlock) {
        try {
          this.floors = await api.getFloorsByBlock(this.selectedBlock);
        } catch (error) {
          console.error("Error fetching floors:", error);
          this.$emit("error", error);
        }
      } else {
        this.floors = [];
      }
    },
    async fetchRooms() {
      if (this.selectedBlock && this.selectedFloor) {
        try {
          this.rooms = await api.getRoomsByBlockAndFloor(
            this.selectedBlock,
            this.selectedFloor
          );
        } catch (error) {
          console.error("Error fetching rooms:", error);
          this.$emit("error", error);
        }
      } else {
        this.rooms = [];
      }
    },
    convertToTree(items, parentId = 0) {
      const result = [];
      items.forEach((item) => {
        if (item.parent_id === parentId) {
          const children = this.convertToTree(items, item.id);
          const newItem = {
            id: item.id,
            name: item.name,
            children: children,
          };
          result.push(newItem);
        }
      });
      return result;
    },
    isFormValid() {
      return !Object.values(this.formErrors).some((error) => error);
    },
    validateForm() {
      this.formErrors.selectedUser =
        this.isAdmin && !this.selectedUser
          ? "Selectați o persoană responsabilă."
          : "";
      this.formErrors.selectedType = !this.selectedType
        ? "Selectați un tip de echipament."
        : "";
      this.formErrors.model = !this.model ? "Introduceți un model." : "";
      this.formErrors.registration = !this.registration
        ? "Introduceți numarul de inventar (codul)."
        : "";
      this.formErrors.registration = !this.registration
        ? "Introduceți un număr de înregistrare."
        : "";
      this.formErrors.selectedBlock = !this.selectedBlock
        ? "Selectați un bloc."
        : "";
      this.formErrors.selectedFloor = !this.selectedFloor
        ? "Selectați un etaj."
        : "";
      this.formErrors.selectedRoom = !this.selectedRoom
        ? "Selectați o cameră."
        : "";
      this.formErrors.selectedDepartment =
        !this.selectedDepartment ||
        this.selectedDepartment.length === 0 ||
        this.selectedDepartment.length > 1
          ? "Selectați doar un departament."
          : "";
    },
    closeDialog() {
      this.dialog = false;
      this.clearFields();
    },
    clearFields() {
      this.formErrors = {
        selectedUser: "",
        selectedType: "",
        model: "",
        registration: "",
        selectedBlock: "",
        selectedFloor: "",
        selectedRoom: "",
        selectedDepartment: "",
      };
      this.selectedUser = null;
      this.selectedType = null;
      this.model = "";
      this.registration = "";
      this.selectedBlock = null;
      this.selectedFloor = null;
      this.selectedRoom = null;
      this.selectedDepartment = null;
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    selectedUser(newVal) {
      this.formErrors.selectedUser = "";
    },
    selectedType(newVal) {
      this.formErrors.selectedType = "";
    },
    model(newVal) {
      this.formErrors.model = "";
    },
    registration(newVal) {
      this.formErrors.registration = "";
    },
    selectedBlock(newVal) {
      this.formErrors.selectedBlock = "";
    },
    selectedFloor(newVal) {
      this.formErrors.selectedFloor = "";
    },
    selectedRoom(newVal) {
      this.formErrors.selectedRoom = "";
    },
    selectedDepartment(newVal) {
      this.isTreeviewDisabled = !!this.selectedDepartment.length > 0;
      this.formErrors.selectedDepartment = "";
    },
  },
};
</script>

<style scoped></style>
