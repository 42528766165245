<template>
  <div class="approval">
    <v-spacer></v-spacer>
    <ErrorAlert :message="errorMessage" @clear-error="clearMessage" />
    <SuccessAlert :message="successMessage" @clear-error="clearMessage" />
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="d-flex align-center pe-2">
            <div>&nbsp; Tabelul Aprobărilor</div>
            <v-spacer></v-spacer>
            <SearchBar @search="handleSearch" />
            <v-spacer></v-spacer>
            <ColumnSelect
              :headers="headers"
              :updateVisibleColumns="updateVisibleColumns"
            />
          </v-card-title>

          <v-divider></v-divider>
          <v-data-table
            :mobile-breakpoint="0"
            :headers="visibleHeaders"
            :items="filteredData"
            :items-per-page="10"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :loading="isLoading"
            loading-text="Incărcare... Aștepta-ți vă rog"
            class="elevation-1"
          >
            <template v-slot:item="{ item, index }">
              <tr :class="{ 'light-green-row': index % 2 !== 0 }">
                <td
                  v-for="(header, hIndex, style) in visibleHeaders"
                  :key="hIndex"
                  :style="header.style ? header.style : {}"
                >
                  {{ getTableCellValue(item, header, index) }}
                  <div v-if="header.value === 'actions'">
                    <v-btn
                      color="success"
                      outlined
                      small
                      shaped
                      @click="onViewClick(item)"
                      >Aprob</v-btn
                    >
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <ApprovalDetails
      ref="approvalDetails"
      :request="selectedRequest"
      :visible="showApprovalPopup"
      @updateRequestData="fetchApprovalData"
    />
  </div>
</template>

<script>
import activityLogsData from "./activityLogs.json";
import TitleComponent from "@/components/elements/TitleComponent.vue";
import ColumnSelect from "@/components/elements/ColumnSelect.vue";
import ApprovalDetails from "./ApprovalDetails.vue";
import ErrorAlert from "@/components/alerts/ErrorAlert.vue";
import SuccessAlert from "@/components/alerts/SuccessAlert.vue";
import SearchBar from "@/components/elements/SearchBar.vue";
import * as api from "./api";
import { mapGetters } from "vuex";
import * as helper from "@/helper/helper.js";

export default {
  name: "RequestApproval",
  components: {
    TitleComponent,
    ApprovalDetails,
    ErrorAlert,
    ColumnSelect,
    SearchBar,
    SuccessAlert,
  },
  data() {
    return {
      headers: [],
      approvalData: [],
      visibleHeaders: [],
      selectedRequest: null,
      showApprovalPopup: false,
      activityLog: activityLogsData,
      search: "",
      sortBy: "id",
      errorMessage: "",
      successMessage: "",
      sortDesc: true,
      isLoading: true,
    };
  },
  methods: {
    async fetchApprovalData() {
      const ApprovalType = {
        USER_CREATED: 1, // acestea sunt id la tipurile de raspunsuri din tabela response_types
        SUPERVISOR_APPROVED: 2,
        RECTOR_APPROVED: 3,
        DTI_DISCRETION: 5,
        DTI_SUPERVISOR_APPROVED: 6,
        CONTABILITY_APPROVED: 11,
      };
      try {
      if (this.isAdmin) {
        this.approvalData = await api.getAllApprovalData();
      } else if (this.isUserSef) {
        this.approvalData = await api.getApprovalDataByTypeAndDepartment(
          ApprovalType.USER_CREATED,
          this.$store.getters.getUser.department
        );
      } else if (this.isRector) {
        this.approvalData = await api.getApprovalDataByType(
          ApprovalType.CONTABILITY_APPROVED
        );
      } else if (this.isContability) { // Add this step for contability approval after rector
        this.approvalData = await api.getApprovalDataByType(
          ApprovalType.SUPERVISOR_APPROVED
        );
      } else if (this.isEngineer) {
        const rectorApprovals = await api.getApprovalDataByType(ApprovalType.RECTOR_APPROVED);
        const dtiApprovals = await api.getApprovalDataByType(ApprovalType.DTI_SUPERVISOR_APPROVED);
        this.approvalData = rectorApprovals.concat(dtiApprovals);
      } else if (this.isDtiSef) {
        const departmentApprovals =
          await api.getApprovalDataByTypeAndDepartment(
            ApprovalType.USER_CREATED,
            this.$store.getters.getUser.department
          );

        const dtiDescretion = await api.getApprovalDataByType(
          ApprovalType.DTI_DISCRETION
        );

        this.approvalData = departmentApprovals.concat(dtiDescretion);
      } else {
        this.approvalData = [];
      }

      this.isLoading = false;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    },
    onViewClick(item) {
      this.selectedRequest = { ...item };
      this.$refs.approvalDetails.show();
    },
    formatCreatedAt(dateString) {
      const result = helper.formatCreatedAt(dateString);
      return result;
    },
    getTableCellValue(item, header, index) {
      if (header.value === "index") return index + 1;
      if (header.value === "user") return item.user;
      if (header.value === "status") return item.status;
      if (header.value === "equipment") return item.equipment;
      if (header.value === "cause") return item.cause;
      if (header.value === "department") return item.department;
      if (header.value === "date") return this.formatCreatedAt(item.date);
      return "";
    },
    sortTable(header) {
      const result = helper.sortTable(header);
      return result;
    },
    updateVisibleColumns() {
      this.visibleHeaders = this.headers.filter((header) => header.visible);
    },
    handleSearch(searchTerm) {
      this.search = searchTerm;
    },
    handleError(message) {
      this.errorMessage = message;
    },
    handleSuccess(message) {
      this.successMessage = message;
    },
    clearMessage() {
      this.errorMessage = "";
      this.successMessage = "";
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    isAdmin() {
      return this.$store.getters.getUser.role === "admin";
    },
    isEngineer() {
      return this.$store.getters.getUser.role === "inginer";
    },
    isDtiSef() {
      return this.$store.getters.getUser.role === "dtisef";
    },
    isUserSef() {
      return this.$store.getters.getUser.role === "usersef";
    },
    isUser() {
      return this.$store.getters.getUser.role === "usersef";
    },
    isRector() {
      return this.$store.getters.getUser.role === "rector";
    },
    isContability() {
      return this.$store.getters.getUser.role === "contability";
    },
    filteredData() {
      const result = helper.filteredData(this.approvalData, this.search);
      return result;
    },
  },
  mounted() {
    this.headers = require("./approvalData.json");
    this.visibleHeaders = this.headers.filter(
      (header) => header.defaultVisible
    );
    this.headers.forEach((header) => {
      header.visible = header.defaultVisible;
    });
    this.fetchApprovalData();
    this.$refs.approvalDetails.$on("error", this.handleError);
    this.$refs.approvalDetails.$on("success", this.handleSuccess);
  },
};
</script>

<style scoped>
.approval .v-data-table::v-deep tbody td {
  font-size: 18px;
}

.v-data-table::v-deep th {
  font-size: 20px !important;
  background-color: #2d8659 !important;
  color: white !important;
  white-space: nowrap;
}
.light-green-row {
  background-color: #ecf9f2;
}
@media (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}
</style>
