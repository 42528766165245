<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay>
    <v-toolbar dark color="#2d8659">
      <v-btn icon dark @click="handleClose()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Profilul meu</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card color="white">
      <div class="space"></div>
      <ErrorAlert :message="errorMessage" @clear-error="clearMessage" />
      <SuccessAlert :message="successMessage" @clear-error="clearMessage" />
      <v-card-text>
        <v-container class="form-container">
          <v-row justify="center">
            <v-col cols="10">
              <p class="error-message">
                *După salvarea datelor veți fi redirecționați pe
                <b>pagina de logare</b>
              </p>
              <v-text-field
                v-model="userName"
                label="Username*"
                required
                :error-messages="formErrors.userName"
              ></v-text-field>
              <v-text-field
                v-model="lastName"
                label="First Name*"
                required
                :error-messages="formErrors.lastName"
              ></v-text-field>
              <v-text-field
                v-model="firstName"
                label="Last Name*"
                required
                :error-messages="formErrors.firstName"
              ></v-text-field>
              <v-text-field
                v-model="email"
                label="Email"
                required
                readonly
                :error-messages="formErrors.email"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn
              style="color: white; background-color: #2d8654"
              variant="text"
              @click="changePassword()"
              >Schimbă parola</v-btn
            >
            <v-btn
              type="submit"
              style="color: white; background-color: #2d8654"
              variant="text"
              @click="showConfirmCard('updateUser')"
              >Save</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-container>
      </v-card-text>
      <ChangePassword
        ref="changePassword"
        :visible="showChangePasswordDialog"
        @password-changed="handlePasswordChanged"
        @loadData="fetchData"
      />
      <ConfirmCard
        v-if="confirmCard"
        :title="confirmTitle"
        @cancel="cancelConfirmCard"
        @confirm="confirmAction"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import * as api from "./api";
import * as helper from "@/helper/helper.js";
import ErrorAlert from "@/components/alerts/ErrorAlert.vue";
import SuccessAlert from "@/components/alerts/SuccessAlert.vue";
import ChangePassword from "@/components/elements/ChangePassword.vue";
import ConfirmCard from "@/components/elements/ConfirmCard.vue";

export default {
  components: {
    ErrorAlert,
    SuccessAlert,
    ChangePassword,
    ConfirmCard,
  },
  data() {
    return {
      user: null,
      dialog: true,
      userName: "",
      firstName: "",
      lastName: "",
      idnp: "",
      email: "",
      password: "",
      errorMessage: "",
      successMessage: "",
      editedUser: null,
      showChangePasswordDialog: false,
      confirmCard: false,
      confirmTitle: "", 
      pendingAction: null, 
      formErrors: {
        userName: "",
        firstName: "",
        lastName: "",
        idnp: "",
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async fetchData() {
      try {
        this.user = await api.getUserById(this.$store.getters.getUser.userId);
        this.editedUser = { ...this.user };

        this.userName = this.user.user_name;
        this.firstName = this.user.first_name;
        this.lastName = this.user.last_name;
        this.idnp = this.user.idnp;
        this.email = this.user.email;
      } catch (error) {
        console.error("Error fetching roles:", error);
        this.$emit("error", error);
      }
    },
    handleClose() {
      this.dialog = false;
      this.$router.go(-1);
    },
    onEscKey(event) {
      if (event.key === "Escape") {
        this.handleClose();
      }
    },
    async updateUser() {
      this.validateForm();

      if (!this.isFormValid()) {
        return;
      }

      try {
        this.editedUser.id = this.user.id;

        const userDataToUpdate = {
          id: this.editedUser.id,
          user_name: this.userName,
          first_name: this.firstName,
          last_name: this.lastName,
          idnp: this.idnp,
          email: this.email,
        };

        await api.updateUser(userDataToUpdate);
        this.handleSuccess("Editare cu succes");
        setTimeout(() => {
          // this.$router.push({ name: "requests" });
          this.$store.dispatch("logout");
          window.location.reload();
        }, 1000);
      } catch (error) {
        console.error("Error updating user:", error);
        this.handleError("Erroare la editare");
      }
    },
    handleError(message) {
      this.errorMessage = message;
    },
    handleSuccess(message) {
      this.successMessage = message;
    },
    clearMessage() {
      this.errorMessage = "";
      this.successMessage = "";
    },
    resetForm() {
      this.formErrors = {
        userName: "",
        firstName: "",
        lastName: "",
        idnp: "",
        email: "",
        password: "",
      };
    },
    validateForm() {
      this.formErrors.userName = this.userName
        ? ""
        : "Username este obligatoriu";
      this.formErrors.firstName = this.firstName
        ? ""
        : "Numele este obligatoriu";
      this.formErrors.lastName = this.lastName
        ? ""
        : "Prenumele este obligatoriu";
      this.formErrors.email = this.validateEmail(this.email)
        ? ""
        : "Adresa de email invalidă sau nu se termină cu @usarb.md";
      this.formErrors.idnp = this.validateIDNP(this.idnp) ? "" : "IDNP invalid";
    },

    validateEmail(email) {
      const isValidEmail = helper.validateEmail(email);
      return isValidEmail;
    },
    validateIDNP(idnp) {
      const isValidIdnp = helper.validateIDNP(idnp);
      return isValidIdnp;
    },
    isFormValid() {
      return !Object.values(this.formErrors).some((error) => error);
    },
    openChangePasswordDialog() {
      this.showChangePasswordDialog = true;
    },
    async handlePasswordChanged() {
      this.handleSuccess(
        "Editare cu succes. Datele de logare au fost trimise pe poșta dumneavoastră."
      );
      setTimeout(() => {
        this.$store.dispatch("logout");
        window.location.reload();
      }, 1000);
    },
    changePassword() {
      this.$refs.changePassword.show();
    },
    showConfirmCard(action) {
      this.confirmCard = true;
      this.pendingAction = action;
      this.confirmTitle = "După salvare va trebui să vă logați din nou. Doriți să continuați?";
    },
    confirmAction() {
      this.confirmCard = false;
      if (this.pendingAction) {
        this[this.pendingAction]();
      }
    },
    cancelConfirmCard() {
      this.confirmCard = false;
      this.pendingAction = null;
    },
  },
  mounted() {
    this.fetchData();
    document.addEventListener("keydown", this.onEscKey);
    // this.$refs.changePassword.$on("error", this.handleError);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onEscKey);
  },
  watch: {
    // showEditPopup(newVal) {
    //   if (!newVal) {
    //     this.resetForm();
    //   }
    // },
    username(newVal) {
      this.formErrors.userName = "";
    },
    firstName(newVal) {
      this.formErrors.firstName = "";
    },
    lastName(newVal) {
      this.formErrors.lastName = "";
    },
    selectedDepartment(newVal) {
      this.formErrors.selectedDepartment = "";
    },
    idnp(newVal) {
      this.formErrors.idnp = "";
    },
    email(newVal) {
      this.formErrors.email = "";
    },
  },
};
</script>

<style scoped>
.form-container {
  background-color: #ffffff;
  padding: 20px;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.space {
  padding-top: 20px;
  background-color: white;
}
.error-message {
  color: red;
  align-items: center;
}
</style>
