<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <DeleteCard
      title="Doriți să ștergeți acest echipament?"
      @cancel="cancelDelete"
      @confirm="confirmDelete"
    />
  </v-dialog>
</template>

<script>
import * as api from "./api";
import DeleteCard from "@/components/elements/DeleteCard.vue";

export default {
  props: {
    equipment: Object,
  },
  components: {
    DeleteCard,
  },
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    show() {
      this.dialogDelete = true;
    },
    cancelDelete() {
      this.dialogDelete = false;
    },
    async confirmDelete() {
      try {
        const isNotEmpty = await api.checkEquipment(this.equipment.id);
        if (isNotEmpty) {
          this.$emit(
            "error",
            "Nu puteți șterge echipamentul în baza căruia sunt create demersuri!"
          );
          this.dialogDelete = false;
          return;
        }
        await api.deleteEquipment(this.equipment.id);
        this.$emit("updateEquipmentData");
        this.$emit("success", "Ștergere cu success");
        this.dialogDelete = false;
      } catch (error) {
        console.error("Error deleting equipment:", error);
        this.dialogDelete = false;
        this.$emit("error", error);
      }
    },
  },
};
</script>
