<template>
  <v-alert
    v-if="message"
    dense
    type="success"
    variant="outlined"
    :width="alertWidth"
    transition="scroll-y-transition"
    class="custom-alert"
  >
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  props: {
    message: String,
  },
  data() {
    return {
      alertWidth: "400px",
    };
  },
  watch: {
    message(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.$emit("clear-error");
        }, 3000);
      }
    },
  },
  mounted() {
    this.adjustWidth();
    window.addEventListener("resize", this.adjustWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustWidth);
  },
  methods: {
    adjustWidth() {
      if (window.innerWidth < 600) {
        this.alertWidth = "90%";
      } else {
        this.alertWidth = "400px";
      }
    },
  },
};
</script>

<style scoped>
.v-alert {
  margin-bottom: 0px;
}
.custom-alert {
  margin-bottom: 0px;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 9999;
}
</style>
