<template>
  <v-dialog v-model="showItemPopup" persistent width="1024">
    <v-card>
      <v-card-title
        >Vizualizare Utilizator
        <v-spacer></v-spacer>
        <v-btn icon dark color="black" @click="showItemPopup = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="userFullName"
                  label="Utilizatorul"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2">
                <v-text-field
                  v-model="mobilePhone"
                  label="Telefon mobil"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2">
                <v-text-field
                  v-model="fixPhone"
                  label="Telefon fix"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="email"
                  label="Email"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="departments[0]"
                  label="Departament"
                  readonly
                ></v-text-field>
              </v-col>              
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import * as api from "./api";

export default {
  props: {
    user: Object,
  },
  data() {
    return {
      showItemPopup: false,
      userFullName: "",
      idnp: "",
      mobilePhone: "",
      fixPhone: "",
      email: "",
      departments: [],
    };
  },
  methods: {
    show() {
      this.showItemPopup = true;
    },
    async loadData() {
  try {
    if (!this.user) return;
    this.departments = [this.user.department];
    this.mobilePhone = this.user.telephone ? this.user.telephone : "Nu există";
    this.fixPhone = this.user.landline ? this.user.landline : "Nu există";
  } catch (error) {
    console.error("Error fetching user data:", error);
    this.$emit("error", error);
  }
},
    resetForm() {
      this.userFullName = "";
      this.mobilePhone = "";
      this.fixPhone = "";
      this.email = "";
      this.departments = [];
    },
  },
  watch: {
    showItemPopup(newVal) {
      if (!newVal) {
        this.resetForm();
      }
    },
    user: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.userFullName = `${newValue.first_name} ${newValue.last_name}`;
          this.email = newValue.email;
          this.mobilePhone = newValue.telephone;
          this.fixPhone = newValue.landline;
          this.departments = [newValue.department]; // Set the department as a single-element array
          this.loadData();
        }
      },
    },
  },
  mounted() {
    this.loadData();
  },
};

</script>

<style scoped></style>
