<template>
  <v-dialog v-model="showApprovalPopup" persistent width="1024">
    <v-card>
      <v-card-title>
        Aprobare
        <v-spacer></v-spacer>
        <v-btn icon dark color="black" @click="showApprovalPopup = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <h3 class="custom-heading">Datele despre Utilizator</h3>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field v-model="user" label="Utilizatorul" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field v-model="department" label="Departament" readonly></v-text-field>
              </v-col>
              <v-col cols="6" sm="2">
                <v-text-field v-model="mobilePhone" label="Telefon mobil" readonly></v-text-field>
              </v-col>
              <v-col cols="6" sm="2">
                <v-text-field v-model="fixPhone" label="Telefon fix" readonly></v-text-field>
              </v-col>
              <v-col cols="12">
                <h3 class="custom-heading">Datele despre Echipament</h3>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field v-model="equipment" label="Echipamentul" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field v-model="model" label="Model" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field v-model="registration" label="Nr Inventar" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field v-model="location" label="Locația" readonly></v-text-field>
              </v-col>
              <v-col cols="9">
                <h3 style="display: inline; color: #424242">Ultima executare:</h3>
                <h3 style="display: inline; color: #e82517">
                  {{
                    this.execution.id === null
                    ? this.execution.message
                    : this.execution.requestType +
                    ", " +
                    this.execution.created_at +
                    " (" +
                    (this.execution.created_at
                      ? calcTimeDifference(this.execution.created_at)
                      : "N/A") +
                    ")."
                  }}
                </h3>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field v-model="cause" label="Cauza" readonly></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="body" label="Comentarii" persistent-hint></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-row justify="end">
              <v-btn v-if="this.getUser.role !== 'inginer'" style="color: white; background-color: #2d8654" variant="text"
                @click="showConfirmCard('reject')" class="mr-2 mt-2">
                Respingere
              </v-btn>
              <!-- Removed the "La examinare DTI" button -->
              <v-btn v-if="this.getUser.role === 'inginer'" style="color: white; background-color: #2d8654" variant="text"
                @click="showConfirmCard('isImposible')" class="mr-2 mt-2">
                Imposibil la moment
              </v-btn>
              <v-btn v-if="this.getUser.role === 'inginer'" style="color: white; background-color: #2d8654" variant="text"
                @click="showConfirmCard('inProcess')" class="mr-2 mt-2">
                În proces de lucrare
              </v-btn>
              <v-btn v-if="this.getUser.role !== 'inginer'" style="color: white; background-color: #2d8654" variant="text"
                @click="showConfirmCard('approb')" class="mr-2 mt-2">
                Aprobare
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card-text>
      <ConfirmCard v-if="confirmCard" :title="confirmTitle" @cancel="cancelConfirmCard" @confirm="confirmAction" />
    </v-card>
  </v-dialog>
</template>

<script>
import * as api from "./api";
import * as helper from "@/helper/helper.js";
import ConfirmCard from "@/components/elements/ConfirmCard.vue";
import { mapGetters } from "vuex";

const ApprovalType = {
  USER_CREATED: 1,
  SUPERVISOR_APPROVED: 2,
  RECTOR_APPROVED: 3,
  REJECTED: 4,
  ENGINEER_APPROVED: 7,
  WORKING: 8,
  IMPOSIBLE: 10,
  CONTABILITY_APPROVED: 11,
};

export default {
  props: {
    request: Object,
  },
  components: {
    ConfirmCard,
  },
  data() {
    return {
      registration: "",
      editedRequest: { ...this.request },
      showApprovalPopup: false,
      user: "",
      equipment: "",
      model: "",
      department: "",
      body: "",
      telephone: "",
      landline: "",
      location: "",
      execution: [],
      confirmCard: false,
      confirmTitle: "",
      pendingAction: null,
      mobilePhone: "",
      fixPhone: "",
      cause: "",
    };
  },
  methods: {
    show() {
      this.showApprovalPopup = true;
    },
    async loadData() {
      try {
        if (!this.request) {
          return;
        }
        this.execution = await api.getLastExecution(
          this.request.userId,
          this.request.equipmentId
        );
        // Assign telephone and landline or set to "Nu există" if empty
        this.mobilePhone = this.request.telephone && this.request.telephone.length > 0 ? this.request.telephone : "Nu există";
        this.fixPhone = this.request.landline && this.request.landline.length > 0 ? this.request.landline : "Nu există";
      } catch (error) {
        console.error("Error fetching contacts:", error);
        this.$emit("error", error);
      }
    },
    async handleApproval(responseTypeId) {
      if (this.getUser.role === "admin") {
        this.showApprovalPopup = false;
        this.$emit("error", "Adminul nu poate aproba demersurile.");
        return;
      }

      let postResponseData = {
        response_type_id: responseTypeId,
        request_id: this.request.requestId,
        created_by: this.getUser.userId,
        comments: this.body,
      };

      let requestData = {
        id: this.request.requestId,
        current_etap: responseTypeId,
      };

      if (responseTypeId === ApprovalType.CONTABILITY_APPROVED) {
        requestData.current_etap = ApprovalType.CONTABILITY_APPROVED;
        requestData.approach_number = null; // Approach number should not be set yet
      }

      if (responseTypeId === ApprovalType.WORKING) {
        const maxApproachNumber = await api.getMaxApproachNumber(this.request.userId, this.request.equipmentId);
        let nextApproachNumber = (maxApproachNumber + 1).toString();

        requestData.approach_number = nextApproachNumber;
      }

      try {
        await api.saveResponseData(postResponseData);
        await api.updateRequests(requestData);

        this.$emit("success", "Success");
        this.$emit("updateRequestData");
        this.showApprovalPopup = false;
      } catch (error) {
        console.error("Error saving user:", error);
        this.$emit("error", error);
      }
    },
    reject() {
      const rejectionType =
        this.getUser.role === "rector" ||
          this.getUser.role === "dtisef" ||
          this.getUser.role === "contability" ||
          this.getUser.role === "usersef"
          ? ApprovalType.REJECTED
          : null;
      if (rejectionType !== null) {
        this.handleApproval(rejectionType);
      } else {
        this.showApprovalPopup = false;
        this.$emit("error", "Nu puteti efectua aceste modificari.");
      }
    },
    isImposible() {
      const rejectionType =
        this.getUser.role === "inginer" ? ApprovalType.IMPOSIBLE : null;
      if (rejectionType !== null) {
        this.handleApproval(rejectionType);
      } else {
        this.showApprovalPopup = false;
        this.$emit("error", "Nu puteti efectua aceste modificari.");
      }
    },
    inProcess() {
      const rejectionType =
        this.getUser.role === "inginer" ? ApprovalType.WORKING : null;
      if (rejectionType !== null) {
        this.handleApproval(rejectionType);
      } else {
        this.showApprovalPopup = false;
        this.$emit("error", "Nu puteti efectua aceste modificari.");
      }
    },
    approb() {
      let approvalType;

      if (this.getUser.role === "user") {
        this.$emit("error", "Nu puteti efectua aceste modificari.");
        return;
      }

      if (this.getUser.role === "usersef") {
        approvalType = ApprovalType.SUPERVISOR_APPROVED;
      } else if (this.getUser.role === "rector") {
        approvalType = ApprovalType.RECTOR_APPROVED;
      } else if (this.getUser.role === "inginer") {
        // Check if contability has approved before allowing the engineer to proceed
        if (this.request.current_etap !== ApprovalType.CONTABILITY_APPROVED) {
          this.$emit("error", "Contabilitatea trebuie să aprobe înainte de a începe lucrul.");
          return;
        }
        approvalType = ApprovalType.ENGINEER_APPROVED;
      } else if (this.getUser.role === "dtisef") {
        if (this.request.status === "Emis de utilizator") {
          approvalType = ApprovalType.SUPERVISOR_APPROVED;
        } else {
          approvalType = ApprovalType.DTI_SUPERVISOR_APPROVED;
        }
      } else if (this.getUser.role === "contability") {
        approvalType = ApprovalType.CONTABILITY_APPROVED;
      } else {
        approvalType = null;
      }

      if (approvalType !== null || approvalType !== undefined) {
        this.handleApproval(approvalType);
      } else {
        this.showApprovalPopup = false;
        this.$emit("error", "Eroare la aprobare.");
      }
    },
    calcTimeDifference(created_time) {
      const result = helper.calcTimeDifference(created_time);
      return result;
    },
    showConfirmCard(action) {
      this.confirmCard = true;
      this.pendingAction = action;
      this.confirmTitle = "Doriți să continuați?";
    },
    confirmAction() {
      this.confirmCard = false;
      if (this.pendingAction) {
        this[this.pendingAction]();
      }
    },
    cancelConfirmCard() {
      this.confirmCard = false;
      this.pendingAction = null;
    },
  },
  watch: {
    request: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.equipment = newValue.equipment;
          this.registration = newValue.registration_number;
          this.user = newValue.user;
          this.department = newValue.department;
          this.model = newValue.model;
          this.location = newValue.equipmentLocation;
          this.cause = newValue.cause;
          this.loadData();
        }
      },
    },
  },
  computed: {
    ...mapGetters(["getUser", "isAdmin"]),
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.custom-heading {
  margin-bottom: 0px;
  color: #424242;
  text-align: center;
}
</style>
