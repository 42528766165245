const isDevelopmentMode = Number(process.env.VUE_APP_PROD_MODE) === 1;

let apiUrl;
if (isDevelopmentMode) {
    apiUrl = process.env.VUE_APP_URL;
} else {
    apiUrl = 'http://localhost:3000/api';
}

// console.log(`API URL in use: ${apiUrl}`);

export default apiUrl;
