<template>
  <v-card width="500px">
    <v-card-title class="text-h5 text-center">{{ title }}</v-card-title>
    <v-card-actions>
      <v-row justify="end" class="mb-2">
        <v-btn
          style="color: white; background-color: #ff5252"
          variant="text"
          @click="confirm"
          class="mr-2 mt-2"
          >OK</v-btn
        >
        <v-btn
          style="color: white; background-color: #424242"
          variant="text"
          @click="cancel"
          class="mr-2 mt-2"
          >Cancel</v-btn
        >
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: String,
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__title {
  font-weight: 400;
}
@media (max-width: 600px) {
  .text-h5 {
    font-size: 10px;
  }
}
</style>
