<template>
  <v-row justify="end">
    <v-dialog v-model="dialog" persistent width="1024">
      <template v-slot:activator="{ props }">
        <v-btn
          x-large
          v-bind="props"
          @click="dialog = true"
          style="color: white; background-color: #2d8654"
          class="mobile-mode"
        >
          Creare Utilizator
        </v-btn>
      </template>
      <v-card>
        <v-form fast-fail @submit.prevent="saveUser">
          <v-card-title>
            <span class="text-h5">Crearea Utilizatorului</span>
            <v-spacer></v-spacer>
            <v-btn icon dark color="black" @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="selectedRole"
                    :items="roles"
                    label="Rol*"
                    item-text="role"
                    item-value="id"
                    required
                    :error-messages="formErrors.selectedRole"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="selectedDepartment"
                    :items="departments"
                    label="Departamentul Șefului Subdiviziunii*"
                    item-text="Denumire"
                    item-value="Denumire"
                    :error-messages="formErrors.selectedDepartment"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="firstName"
                    label="Numele*"
                    hint="exemplu: Petricescu"
                    required
                    :error-messages="formErrors.firstName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="lastName"
                    label="Prenumele*"
                    hint="exemplu: Vasile"
                    required
                    :error-messages="formErrors.lastName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="email"
                    label="Email*"
                    required
                    :error-messages="formErrors.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="telephone"
                    label="Telefon mobil"
                    :counter="9"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="landline"
                    label="Telefon fix*"
                    required
                    :error-messages="formErrors.landline"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                  <v-checkbox
                    v-model="isActive"
                    label="Este Activ"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
            <small>*câmpurile obligatorii</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="color: white; background-color: #2d8654"
              variant="text"
              type="submit"
            >
              Salvează
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import * as api from "./api";
import * as helper from "@/helper/helper.js";

export default {
  data() {
    return {
      dialog: false,
      roles: [],
      departments: [],
      selectedRole: null,
      selectedDepartment: null,
      firstName: "",
      lastName: "",
      idnp: "",
      email: "",
      landline: "",
      telephone: "",
      isActive: true,
      formErrors: {
        selectedRole: "",
        selectedDepartment: "",
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        landline: "",
      },
    };
  },
  computed: {},
  methods: {
    async fetchData() {
      try {
        this.roles = await api.fetchRoles();        
        const allDepartments = await api.getDepartments();
        this.departments = allDepartments
          .filter(department => department.parent_id === 1)
          .map(dept => dept.name);
      } catch (error) {
        console.error("Error fetching roles:", error);
        this.$emit("error", error);
      }
    },
    async saveUser() {
      this.validateForm();

      if (!this.isFormValid()) {
        return;
      }
    const formattedLandline = this.validateLandline(this.landline);
    if (!formattedLandline) {
        this.formErrors.landline = "Telefon fix invalid";
        return;
    }

    const postData = {
      
        role_id: this.selectedRole,
        first_name: this.firstName,
        last_name: this.lastName,
        idnp: this.idnp,
        email: this.email,
        department: this.selectedDepartment,
        isActive: this.isActive,
        landline: formattedLandline, 
        telephone: this.telephone,
    };

      try {
        await api.saveUserData(postData);
        this.$emit(
          "success",
          "Creare cu succes. Parola a fost trimisă utilizatorului pe poșta corparativă"
        );
        this.$emit("updateUserData");
        this.dialog = false;
        this.clearFields();
      } catch (error) {
        console.error("Error saving user:", error);
        this.$emit("error", error);
        this.dialog = false;
      }
    },
    validateForm() {
      this.formErrors.selectedRole = this.selectedRole
        ? ""
        : "Selectați un rol";
      this.formErrors.firstName = this.firstName
        ? ""
        : "Numele este obligatoriu";
      this.formErrors.lastName = this.lastName
        ? ""
        : "Prenumele este obligatoriu";
      if (this.selectedRole) {
        this.formErrors.selectedDepartment = this.selectedDepartment
          ? ""
          : "Departamentul este obligatoriu";
      }
      this.formErrors.email = this.validateEmail(this.email)
        ? ""
        : "Adresa de email invalidă";
    },
    validateEmail(email) {
      const isValidEmail = helper.validateEmail(email);
      return isValidEmail;
    },
    validateIDNP(idnp) {
      const isValidIdnp = helper.validateIDNP(idnp);
      return isValidIdnp;
    },
    validateLandline(landline) {
      return helper.validateLandline(landline);
    },
    isFormValid() {
      return !Object.values(this.formErrors).some((error) => error);
    },
    closeDialog() {
      this.dialog = false;
      this.clearFields();
    },
    clearFields() {
      this.formErrors = {
        selectedRole: "",
        selectedDepartment: "",
        firstName: "",
        lastName: "",
        idnp: "",
        email: "",
        telephone: "",
        landline: "",
      };
      this.selectedRole = null;
      this.selectedDepartment = null;
      this.firstName = "";
      this.lastName = "";
      this.idnp = "";
      this.email = "";
      this.telephone = "",
      this.landline = "",
      this.isActive = true;
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    selectedRole(newVal) {
      if (newVal === 2) {
        this.selectedDepartment = null;
      }
      this.formErrors.selectedRole = "";
    },
    selectedDepartment(newVal) {
      this.formErrors.selectedDepartment = "";
    },
    firstName(newVal) {
      this.formErrors.firstName = "";
    },
    lastName(newVal) {
      this.formErrors.lastName = "";
    },
    selectedDepartment(newVal) {
      this.formErrors.selectedDepartment = "";
    },
    idnp(newVal) {
      this.formErrors.idnp = "";
    },
    email(newVal) {
      this.formErrors.email = "";
    },
    telephone(newVal) {
      this.formErrors.telephone = "";
    },
    landline(newVal) {
      this.formErrors.landline = "";
    }
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .mobile-mode {
    font-size: 14px;
    padding: 2px 2px;
    min-width: auto;
  }
  .v-btn:not(.v-btn--round).v-size--x-large {
    height: 40px;
    padding: 2px 8px;
  }
}
</style>
