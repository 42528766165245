import axios from 'axios';
import store from './store';

const instance = axios.create();

instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            store.dispatch('logout');
            window.location.reload();
            router.push('/login');
        }
        return Promise.reject(error);
    }
);

export default instance;
