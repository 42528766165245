<template>
  <h3 class="custom-font">{{ title }}</h3>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style scoped>
.custom-font {
  font-size: 25px;
  color: #404040;
}
</style>
